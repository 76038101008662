import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Select = _resolveComponent("Select")!
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Form = _resolveComponent("Form", true)!
  const _component_Spin = _resolveComponent("Spin")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "RuleFrom",
    width: 760,
    maskClosable: false,
    visible: _ctx.visible,
    onCancel: _cache[28] || (_cache[28] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx._getTitle()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Spin, {
        spinning: _ctx.$store.state.loadding
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Form, {
            model: _ctx.data,
            ref: "_form",
            class: "form",
            layout: "vertical",
            "validate-messages": _ctx.validateMessages
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Row, { gutter: [24,24] }, {
                default: _withCtx(() => [
                  _createVNode(_component_Col, {
                    xs: 24,
                    sm: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.name'),
                        message: "errorMessage",
                        name: ['title'],
                        rules: [{ required: true}]
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('product.field.name'),
                            value: _ctx.data.title,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.title) = $event))
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 24,
                    sm: 24
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.description')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TextArea, {
                            value: _ctx.data.description,
                            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.description) = $event)),
                            class: "message-textarea"
                          }, null, 8, ["value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.rent')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('0.00'),
                            value: _ctx.data.price,
                            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.price) = $event)),
                            disabled: _ctx.data.price_type == 1 ? false : true
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.price_type,
                                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.price_type) = $event)),
                                options: _ctx.groups,
                                placeholder: _ctx.$t('public.placeholder.please_select')
                              }, null, 8, ["value", "options", "placeholder"])
                            ]),
                            _: 1
                          }, 8, ["placeholder", "value", "disabled"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.clientProfile')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: 0,
                            disabled: _ctx.data.customer_input == 1 ? true : false,
                            value: _ctx.data.customers,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.customers) = $event))
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.customer_input,
                                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.customer_input) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SelectOption, { value: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.input')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_SelectOption, { value: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.no_limit')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["disabled", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.employeeAccount')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('product.field.employeeAccount'),
                            disabled: _ctx.data.account_input == 1 ? true : false,
                            value: _ctx.data.accounts,
                            "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.accounts) = $event))
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.account_input,
                                "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.account_input) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SelectOption, { value: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.input')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_SelectOption, { value: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.no_limit')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["placeholder", "disabled", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.marketingSms')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('0'),
                            disabled: _ctx.data.message_input == 1 ? true : false,
                            value: _ctx.data.messages,
                            "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.data.messages) = $event))
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.message_input,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.message_input) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SelectOption, { value: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.input')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_SelectOption, { value: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.no_limit')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["placeholder", "disabled", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.automationRule')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('0'),
                            disabled: _ctx.data.rule_input == 1 ? true : false,
                            value: _ctx.data.rules,
                            "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.data.rules) = $event))
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.rule_input,
                                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.data.rule_input) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SelectOption, { value: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.input')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_SelectOption, { value: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.no_limit')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["placeholder", "disabled", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.devicesConnect')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('0'),
                            disabled: _ctx.data.device_input == 1 ? true : false,
                            value: _ctx.data.devices,
                            "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.data.devices) = $event))
                          }, {
                            addonBefore: _withCtx(() => [
                              _createVNode(_component_Select, {
                                value: _ctx.data.device_input,
                                "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.data.device_input) = $event))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_SelectOption, { value: 0 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.input')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_SelectOption, { value: 1 }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.$t('product.field.no_limit')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"])
                            ]),
                            _: 1
                          }, 8, ["placeholder", "disabled", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_Row, {
                gutter: [24,24],
                style: {"margin-top":"15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.marketingData')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            checked: _ctx.data.is_data_analysis,
                            "onUpdate:checked": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.data.is_data_analysis) = $event)),
                            value: "1"
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.whatsapp')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_whastapp_help,
                            "onUpdate:checked": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.data.is_whastapp_help) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.app')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_app,
                            "onUpdate:checked": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.data.is_app) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_Row, {
                gutter: [24,24],
                style: {"margin-top":"15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.shopify')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_shopify,
                            "onUpdate:checked": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.data.is_shopify) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.permissions')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_user_power,
                            "onUpdate:checked": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.data.is_user_power) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.api')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_api_connect,
                            "onUpdate:checked": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.data.is_api_connect) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_Row, {
                gutter: [24,24],
                style: {"margin-top":"15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.customerService')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_service,
                            "onUpdate:checked": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.data.is_service) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.team')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_team_distribute,
                            "onUpdate:checked": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.data.is_team_distribute) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.hideContact')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_hide_contact,
                            "onUpdate:checked": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.data.is_hide_contact) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_Row, {
                gutter: [24,24],
                style: {"margin-top":"15px"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.bi')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_business_ai,
                            "onUpdate:checked": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.data.is_business_ai) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.sla')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.is_sla,
                            "onUpdate:checked": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.data.is_sla) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.training')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Checkbox, {
                            value: "1",
                            checked: _ctx.data.exclusive_service,
                            "onUpdate:checked": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.data.exclusive_service) = $event))
                          }, null, 8, ["checked"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('public.name.status')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Select, {
                            value: _ctx.data.status,
                            "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.data.status) = $event)),
                            placeholder: _ctx.$t('public.placeholder.please_select')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_SelectOption, { value: 0 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('public.status.2')), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_SelectOption, { value: 1 }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('public.status.1')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["value", "placeholder"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_Col, {
                    xs: 8,
                    sm: 8
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FormItem, {
                        label: _ctx.$t('product.field.order')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Input, {
                            placeholder: _ctx.$t('0'),
                            value: _ctx.data.sort,
                            "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.data.sort) = $event))
                          }, null, 8, ["placeholder", "value"])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model", "validate-messages"])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}