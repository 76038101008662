import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Alert,Switch,Progress} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, WhatsAppOutlined} from "@ant-design/icons-vue";
import { Device as cApi } from "@/api/Device";
import CustomFrom from "@/views/vue/Home/device/form.vue";
import EditFrom from "@/views/vue/Home/device/edit.vue";
import sessionForm from "@/views/vue/Home/device/session.vue";
import { connect } from "puppeteer";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    WhatsAppOutlined,
    Menu,
    MenuItem: Menu.Item,
    CustomFrom,
    Alert,
    EditFrom,
    Switch,
    sessionForm,
    Progress,
  },
  data() {
    return {
      search: "",
      order: "",
      pagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("device.table.id"), fixed: "left", dataIndex: "id", key: "id" },
        { title: this.$t("device.table.name"), fixed: "left",  key: "name" },
        { title: this.$t("device.table.plan"), key: "title",dataIndex: "title" },
        { title: this.$t("device.table.status"),  fixed: "left", key: "state" },
        { title: this.$t("device.table.is_robot"),  key: "is_robot" },
        { title: this.$t("device.table.msg"),  key: "text_messages" },
        { title: this.$t("device.table.media_msg"),  key: "media_messages" },
        { title: this.$t("device.table.expire_day"), dataIndex: "expire_day", key: "expire_day" },
        // { title: this.$t("device.table.create_at"), dataIndex: "created_at", key: "created_at" },
        { title: this.$t("public.name.action"), key: "action",align:"right" },
      ],
      rowSelection : ref({
        checkStrictly: false,
          onChange: (selectedRowKeys: (string | number)[]) => {
        },
      }),
    };
  },
  methods: {
    chang_page(pagination: any) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      
      this.loading = true;
      const api = new cApi();
      api
        .get({
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          search: this.search,
          order: this.order,
        })
        .then((response:any) => {
          this.loading = false;
          if (0 === parseInt(response.data.errno)) {
            this.list = response.data.data;
            // this.pagination.total = response.data.data.count
            // this.pagination.current = response.data.data.currentPage
          } else {
            this.list = [];
          }
        });
    },
    
    add(pid: number) {
      this.$refs.refRuleEdit.addShow(pid);
    },
    edit(id: number,data:any) {
      this.$refs.refRuleEditFrom.editShow(id,data);
    },
    del(id: number) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new cApi();
          api.delete({id:id}).then((response:any) => {
            if (0 === parseInt(response.data.errno)) {
              this.get_list();
              message.success(response.data.errmsg);
            } else {
              message.error(response.data.errmsg);
            }
          });
        },
      });
    },
    handleTableChange(pagination: any){  // 分页
      this.pagination = pagination;
      this.get_list();
    },
    // 改变状态
    onChangeState(e:any,record:any){
      var oldState = record.is_robot;
      record.is_robot = record.is_robot == 1 ? 0 : 1;
      //打開開關前，先檢測是否已經設定了nlp_client_email和nlp_private_key
      if(record.is_robot == 1){
        if(!record.nlp_client_email || !record.nlp_private_key || !record.nlp_project_id || !record.nlp_language_code){
          record.is_robot = oldState
          message.error(this.$t('device.name.pleaseSetNlp'));
          return
        }
      }

      const api = new cApi();
      api.changestae({
        is_robot: record.is_robot,
        id:record.id
      })
      .then((response:any) => {
        this.loading = false;
        if (0 === parseInt(response.data.errno)) {
          
        } else {
          message.success(response.data.errmsg);
          record.is_robot = oldState;
        }
      });
    },
    onNextStep(){

    },
    connect(device:any){
      this.$refs.refSession.addShow({
        device_id:device.id,
        name:device.name,
        remaker:device.remaker,
        plan_id:device.plan_id,
        device:{
          description:device.remaker,
          product_id:device.plan_id,
          alias:device.name,
        }
      });
    }
  },
  mounted() {
    this.get_list();
    
  },
})
export default class Rule extends Vue {}