
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Device as Vapi } from "@/api/Device";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    TextArea:Input.TextArea
  },
  data() {
    return {
      visible: false,
      actionType: "",
      groups:[
          {
            value: "whatsapp",
            label: "WhatsApp",
        },
        {
            value: "facebook",
            label: "Facebook",
        },
        {
            value: "twitter",
            label: "Twitter",
        }
      ],
      data: {
        name: "",
        remaker:'',
        device_id:0
      },
      requiredPassword:true,
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
      clientId:null,
      qr:"loading qrcode",
      isLoadingQr:false,
      modalFormQr:null,
      hasLoadQr:false,
    };
  },
  methods: {
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      this.data = data
      this.visible = true;
    },
   
    // reset data
    _resetData() {
      this.data = {
        name: "",
        remaker:'',
        number:null,
        device_id:0
      };
    },
    send(){
        let api = new Vapi();
        let res;
        let _this = this;
        this.$refs["_form"].validate().then(() => {
        api.put(this.data).then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              this.isLoadingQr = true;
              this.visible = false;
              message.success(response.data.errmsg);
              this.$emit("updateList");
            } else {
              message.error(response.data.errmsg);
            }
          });
          
      }).catch();
    }
  },
  mounted() {
    // 监听后端发送来的消息
    var that = this;
    
  }
})
export default class RuleEdit extends Vue {}
