
  import { ref } from "vue";
  import { Options, Vue } from "vue-class-component";
  import { Modal, Row, Col, Form, Input, Select, message,Spin } from "ant-design-vue";
  import { ArrowRightOutlined } from "@ant-design/icons-vue";
  import { Payment as payApi } from "@/api/Payment";
  
  @Options({
    name: "Pay",
    components: {
      Modal,
      Row,
      Col,
      Form,
      FormItem: Form.Item,
      Input,
      Select,
      SelectOption: Select.Option,
      TextArea:Input.TextArea,
      ArrowRightOutlined,
      Spin
    },
    data() {
      return {
        visible: false,
        actionType: "",
        data: {
          amount: 0,
          remarks: "",
        },
        datas:{
            channel:'',
            secret_key:'',
            sec_pin:'',
            pin:''
        },
        isPaymentForm:true,
        validateRules: {
          name: [
            {
              required: true,
              message: this.$t("public.validate.required.name"),
              trigger: "blur",
            },
          ],
        },
      };
    },
    mounted(){
        
    },
    methods: {
        addShow() {
            this.actionType = "add";
            this.visible = true;
            this.handleQuestInfo();
            this.data = {
                    amount: 0,
                    remarks: "",
                };
        },
        handleQuestInfo(){
            var cApi = new payApi();
            this.$store.commit("setLoading",true);
            cApi.questInfo({}).then((response: any) => {
                this.$store.commit("setLoading",false);
                if (0 == parseInt(response.data.errno)) {
                    let data = response.data.data;
                    if(data.channel != undefined){
                        this.datas = {
                            channel:data.channel,
                            secret_key:data.secret_key,
                            sec_pin:data.sec_pin,
                            pin:data.pin
                        }
                        this.isPaymentForm = true;
                    }else{
                        this.isPaymentForm = false;
                    }
                    
                }else {
                    message.error(response.data.errmsg);
                }
            })
        },
        handleSend() {
            var cApi = new payApi();
            this.$store.commit("setLoading",true);
            if(this.isPaymentForm){
                cApi.paylike(this.data).then((response: any) => {
                    this.$store.commit("setLoading",false);
                    if (0 == parseInt(response.data.errno) && response.data.data.url != '') {
                        this.visible = false;
                        this.$emit("updateInputContent",response.data.data);
                    }else if (402 == parseInt(response.data.errno)) {
                        message.error(response.data.errmsg);
                        this.isPaymentForm = false;
                    }else{
                        message.error(response.data.errmsg);
                    }
                })
            }else{
                cApi.setupel(this.datas).then((response: any) => {
                    this.$store.commit("setLoading",false);
                    if (0 == parseInt(response.data.errno)) {
                        message.success(response.data.errmsg);
                        this.isPaymentForm = true;
                    } else {
                        message.error(response.data.errmsg);
                    }
                })
            }
        }
    },
  })
  export default class GroupEdit extends Vue {}
  