import {Base, Axios} from "@/api/Base"

export class General extends Base {
    path = "general"
    config(params:any): any {
        return Axios.post("general/config", params)
    }
    email(params:any): any{
        return Axios.post("general/email", params)
    }
    info(params:any): any {
        return Axios.get("general/info", { params: params })
    }
    logs(params:any): any {
        return Axios.get("general/logs", { params: params })
    }
}