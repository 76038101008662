import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
import { Rule as ruleApi } from "@/api/Rule";
import RuleFrom from "@/views/vue/Home/rule/form.vue";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    RuleFrom,
  },
  data() {
    return {
      search: "",
      order: "",
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("rule.table.name"), fixed: "left", dataIndex: "title", key: "title" },
        { title: this.$t("rule.table.path"), dataIndex: "path", key: "path" },
        { title: this.$t("rule.table.icon"), key: "icon" },
        { title: this.$t("rule.table.condition"), dataIndex: "condition", key: "condition" },
        { title: this.$t("rule.table.status"), key: "status" },
        { title: this.$t("public.name.action"), key: "action",align:"right" },
      ],
      rowSelection : ref({
        checkStrictly: false,
        onChange: (selectedRowKeys: (string | number)[]) => {
        },
        
      })
    };
  },
  methods: {
    chang_page(pagination: any) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new ruleApi();
      api
        .getList({
          page: this.tablePagination.current,
          limit: this.tablePagination.pageSize,
          search: this.search,
          order: this.order,
        })
        .then((response:any) => {
          this.loading = false;
          if (0 === parseInt(response.data.errno)) {
            this.list = response.data.data;
          } else {
            this.list = [];
          }
        });
    },
    
    add(pid: number) {
      this.$refs.refRuleEdit.addShow(pid);
    },
    edit(id: number,data:any) {
      this.$refs.refRuleEdit.editShow(id,data);
    },
    del(id: number) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new ruleApi();
          api.delete({id:id}).then((response:any) => {
            if (0 === parseInt(response.data.errno)) {
              this.get_list();
              message.success(response.data.errmsg);
            } else {
              message.error(response.data.errmsg);
            }
          });
        },
      });
    },
    getMenus(){
      
    }
  },
  mounted() {
    this.get_list();
  },
})
export default class Rule extends Vue {}