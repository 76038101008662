
import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message,Switch} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
import { Payment as cApi } from "@/api/Payment";
import CustomFrom from "@/views/vue/Home/addon/paymentForm.vue";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    Switch,
    CustomFrom
  },
  data() {
    return {
      search: "",
      order: "",
      
      loading: false,
      list: [],
      columns: [
        { title: this.$t("payment.table.id"), fixed: "left", dataIndex: "id", key: "id" },
        { title: this.$t("payment.table.name"), fixed: "left", dataIndex: "name", key: "name" },
        { title: this.$t("payment.table.code"), fixed: "left", dataIndex: "code", key: "code" },
        { title: this.$t("payment.table.status"),  key: "status" },
        { title: this.$t("payment.table.create_at"), dataIndex: "created_at", key: "created_at" },
        { title: this.$t("public.name.action"), key: "action",align:"right" },
      ],
      rowSelection : ref({
        checkStrictly: false,
          onChange: (selectedRowKeys: (string | number)[]) => {
        },
      })
    };
  },
  methods: {
   get_list() {
      this.loading = true;
      const api = new cApi();
      api
        .get({
          order: this.order,
        })
        .then((response:any) => {
          this.loading = false;
          if (0 === parseInt(response.data.errno)) {
            this.list = response.data.data;
          } else {
            this.list = [];
          }
        });
    },
    // 改变状态
    onChangeState(e:any,record:any){
      var oldState = record.status;
      record.status = record.status == 1 ? 0 : 1;
      const api = new cApi();
      api.changestae({
        status: record.status,
        id:record.id
      })
      .then((response:any) => {
        this.loading = false;
        if (0 === parseInt(response.data.errno)) {
          
        } else {
          message.success(response.data.errmsg);
          record.status = oldState;
        }
      });
    },
    edit(code: string,data:any) {
      this.$refs.refForm.editShow(code,data);
    },
  },
  mounted() {
    this.get_list();
  },
})
export default class Rule extends Vue {}
