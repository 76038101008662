import {Base, Axios} from "@/api/Base"

export class Book extends Base {
    path = "book"
    index(params:any): any {
        return Axios.get("book/index", { params: params })
    }
    
    get(params:any): any {
        return Axios.get("book/get", { params: params })
    }

    getAll(): any {
        return Axios.get("book/getAll")
    }
    
}