
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Typography, Col, Form, Input, Select, message, Alert,Progress,Result,Button } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined,CloseCircleOutlined } from "@ant-design/icons-vue";
import { Auth as aApi } from "@/api/Auth";
import { Meal as mApi } from "@/api/Meal";
import { Device as dApi } from "@/api/Device";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
    TextArea:Input.TextArea,
    Progress,
    Result,
    Button
  },
  data() {
    return {
        validateMessages:{
            required: '${label} is required!',
        },
        visible: false,
        percent: 0,
        showTimeOut: false,
        timer:null,
        data:{
            name:null,
            remaker:null,
            user_id:null,
            plan_id:null,
        },
        customes:[],
        plans:[]
    };
  },
  methods: {
   
    // reset data
    _resetData() {
    },
    close(){
      this.visible = false;
    },
    getCustomers(){
        const api = new aApi();
        const  that = this;
        api.items({}).then((response:any) => {
                if(response.data.data){
                    response.data.data.forEach(function(item:any,index:any){
                        that.customes.push({
                            value:item.id,
                            label:item.nickname+" / "+item.email
                        });
                    })
                    console.log(that.customes)
                }
            });
    },
    getPlans(){
        const api = new mApi();
        const  that = this;
        api.items({}).then((response:any) => {
                if(response.data.data){
                    response.data.data.forEach(function(item:any,index:any){
                        that.plans.push({
                            value:item.id,
                            label:item.title
                        });
                    })
                    console.log(that.plans)
                }
            });
    },
    send(){
        const that = this;
        this.$refs["_form"].validate().then(() => {
            const api = new dApi();
            api.cusadd(this.data).then((response:any) => {
                if (0 == parseInt(response.data.errno)) {
                    message.success(response.data.errmsg,2,function(){
                        that.$router.push('index');
                    });
                } else {
                    message.error(response.data.errmsg);
                }
            });
        })
    }
  },
  mounted() {
        this.getCustomers();
        this.getPlans();
  }
})
export default class RuleEdit extends Vue {}
