import {Base, Axios} from "@/api/Base"

export class FastAnswer extends Base {
    path = "answer"
    get(params:any): any {
        return Axios.get("answer/index", { params: params })
    }
    add(params:any): any {
        return Axios.post("answer/add", params)
    }
    edit(params:any): any {
        return Axios.post("answer/edit", params)
    }
    delete(params:any): any {
        return Axios.delete("answer/delete", { params: params })
    }
    all(params:any): any {
        return Axios.get("answer/all", { params: params })
    }
}