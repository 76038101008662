
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message,Tree } from "ant-design-vue";
import { Group as ruleApi } from "@/api/Group";
import { Rule as rApi } from "@/api/Rule";

@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Tree,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      groups:[],
      treeData:[],
      checkedKeys: {
        checked: [],
        halfChecked: [],
      },
      data: {
        name: "",
        status: 1,
        rules: [],
      },
      requiredPassword:true,
      validateMessages:{
        required: 'field is required!',
       
      },
 
    };
  },
  methods: {
    async addShow(pid: number) {
      this.actionType = "add";
      this._resetData();
      this.data.pid = pid;
      this.visible = true;
    },
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      data.password = '';
      this.data = data
      this.checkedKeys = {
        checked: [],
        halfChecked: [],
      };
      var tempRules:string[] = this.data.rules.split(',');
      var tempRulesId = tempRules.map(item=>{
        return parseInt(item)
      })
      tempRulesId.forEach((id) => {
        var isHalf = this.isHalfChecked(id,tempRules);
        if(isHalf){
          this.checkedKeys.halfChecked.push(id)
        }else{
          this.checkedKeys.checked.push(id)
        }
      })


      this.visible = true;
      this.requiredPassword = false;
    },
    // send put/post
    send() {
      let api = new ruleApi();
      let res;
      console.log(this.data)
      this.$refs["_form"].validate().then(() => {
        switch (this.actionType) {
            case "add":
              res = api.post(this.data);
              break;
            case "edit":
              if (!this.data.id && this.data.id <= 0) {
                message.warning(this.$t("rule.validate.required.id"));
                return false;
              }
              res = api.put(this.data);
              break;
            default:
              return false;
          }
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
      
    },
    
    // reset data
    _resetData() {
      this.data = {
        name: "",
        status: 1,
      };
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
    getAuthorityTree() {
      const api = new rApi();
      api.getAll().then((response:any) => {
        
        if (0 == parseInt(response.data.errno)) {
          // this.selectList.authorityList = response.data.data;
          let treeData = [];
          treeData = this.tree_init(response.data.data);
          this.treeData = treeData;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    tree_init(data : any) {
      var parentArr = data.filter((item :any) => {
        return !item.pid;
      });
      var resultArr:any = [];
      parentArr.forEach((item:any) => {
        var children = this.getChildren(item, data);
        if (children.length > 0) {
          resultArr.push({
            key: item.id,
            value: item.id,
            title: this.$t(item.title),
            children: children,
          });
        } else {
          resultArr.push({
            key: item.id,
            value: item.id,
            title: this.$t(item.title),
          });
        }
      });
      return resultArr;
    },
    getChildren(data: any, list:any) {
      let arr:any = [];
      list.forEach((item:any) => {
        if (data.id === item.pid) {
          let subArr = this.getChildren(item, list);
          if (subArr.length === 0) {
            arr.push({
              key: item.id,
              value: item.id,
              title: this.$t(item.title),
            });
          } else {
            arr.push({
              key: item.id,
              value: item.id,
              title: this.$t(item.title),
              children: subArr,
            });
          }
        }
      });
      return arr;
    },
    buildList(tree:any) {
      var list:any = []
      if(Array.isArray(tree)){
        tree.forEach((item) => {
          list.push(item)
          if (item.children != undefined && item.children.length !== 0) {
            var subList = this.buildList(item.children)
            list = list.concat(subList)
          }
        })
      }else{
        list.push(tree)
      }
      return list
    },
    //遍历树结构，获取某节点所有子节点的id
    getTreeChildrenId(children:any){
      var list:any = []
      children.forEach((item:any) => {
        list.push(item.key)
        if(item.children != null){
          list.push(this.getTreeChildrenId(item.children))
        }
      })
      return list
    },
    isContainArr(parent:number[], child:number[]) {
      return child.every(item => {
        return parent.some(v => {
          return item == v
        })
      })
    },
    isHalfChecked(id:number,dataArr:any){
      
      var list = this.buildList(this.treeData)
      var treeNode = list.find((item:any) => {
        return item.key === id
      })
      if(treeNode && treeNode.children != undefined){
        var arr = this.getTreeChildrenId(treeNode.children)
        return !this.isContainArr(dataArr,arr)
      }else{
        return false
      }
      
    },
    checkTree(checkedKeys:any,e:any){
      let concatTreeData =  checkedKeys.concat(e.halfCheckedKeys)
      this.data.rules = concatTreeData.join(",");
    }
    
  },
  mounted(){
    this.getAuthorityTree();
  }
})
export default class RuleEdit extends Vue {}
