import {Base, Axios} from "@/api/Base"

export class FakeNlp extends Base {
    path = "fake_nlp"
    index(params:any): any {
        return Axios.get("fake_nlp/index", { params: params })
    }
    
    get(params:any): any {
        return Axios.get("fake_nlp/get", { params: params })
    }

    getAll(): any {
        return Axios.get("fake_nlp/getAll")
    }
    
}