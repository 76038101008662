import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DescriptionsItem = _resolveComponent("DescriptionsItem")!
  const _component_Descriptions = _resolveComponent("Descriptions")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "CompanyDetail",
    width: "60%",
    visible: _ctx.visible,
    onCancel: _cache[0] || (_cache[0] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _cache[1] || (_cache[1] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    title: _ctx.$t('order.name.detailTitle'),
    zIndex: 9999999900
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Descriptions, null, {
        default: _withCtx(() => [
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.id')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.id), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.product')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.product), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.payment_name')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.payment_name), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.status')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.status), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.amount')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.amount), 1)
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_DescriptionsItem, {
            label: _ctx.$t('order.table.created_at')
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.data.created_at), 1)
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}