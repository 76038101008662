
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, InputNumber } from "ant-design-vue";
import { Payment as Vapi } from "@/api/Payment";

@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    InputNumber,
    TextArea:Input.TextArea
  },
  data() {
    return {
      visible: false,
      actionType: "",
      fields:[],
      groups:[
          {
            value: "whatsapp",
            label: "WhatsApp",
        },
        {
            value: "facebook",
            label: "Facebook",
        },
        {
            value: "twitter",
            label: "Twitter",
        }
      ],
      data: {
        name: "",
        option: {},
        sort: '',
        status: 1,
        remaker: "",
        image:null
      },
      
      requiredPassword:true,
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
 
    };
  },
  methods: {
    async addShow(pid: number) {
      this.actionType = "add";
      this._resetData();
      this.data.pid = pid;
      this.visible = true;
    },
    async editShow(code: string,data:any) {
      this.actionType = "edit";
      data.password = '';
      this.data = data
      this.visible = true;
      this._resetData(data);
      this.requiredPassword = false;
      this._getFields(code)
     
    },
    // send put/post
    send() {
      let api = new Vapi();
      let res;
      this.$refs["_form"].validate().then(() => {
        switch (this.actionType) {
            case "add":
              res = api.post(this.data);
              break;
            case "edit":
              if (!this.data.id && this.data.id <= 0) {
                message.warning(this.$t("rule.validate.required.id"));
                return false;
              }
              res = api.put(this.data);
              break;
            default:
              return false;
          }
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
      
    },
    // reset data
    _resetData(data:any) {
      this.data = {
        name: '',
      };
      this.fields = [];
      this.data = Object.assign(this.data,data)
      console.log(this.data)
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
    _getFields(code:string){
       let api = new Vapi();
       let that = this;
      var res = api.find({code:code}).then((response:any) => {
         if(response.data.errno == 0){
          this.fields = response.data.data.fields;
          let option = response.data.data.option;
          this.data.option = option;
         }else{
          message.warning(response.data.errmsg);
         }
      });
    }
  },
})
export default class RuleEdit extends Vue {}
