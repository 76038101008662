
import { Options, Vue } from "vue-class-component";
import { createVNode, defineComponent } from 'vue';
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Select, Form, Card,Spin} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons-vue";
import { Payment as cApi } from "@/api/Payment";
import { Trades as tApi } from "@/api/Trades";


@Options({
  name: "Payment",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    Modal,
    Select,
    SelectOption: Select.Option,
    Form,
    FormItem: Form.Item,
    Card,
    CardMeta: Card.Meta,
    Spin,
  },
  data() {
    return {
      data: null,
      visible: false,
      form: {
        payment: null,
        product_id: null,
        amount: null,
      },
      selectList: {
        paymentMethodList: []
      },
      spinning:false,
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
    };
  },
  methods: {
    paymentForm(data:any){
        console.log(data);
        this.data = data;
        this.form.product_id = data.item_id;
        this.form.alias = data.name;
        this.form.amount = data.price;
        this.form.description = data.remaker;
        this.visible = true;
    },
    getPaymentItem() {
      let api = new cApi;
      api.items().then((response: any) => {
        this.selectList.paymentMethodList = [];
        if (response.data.errno == 0) {
          this.selectList.paymentMethodList = response.data.data
        }
      })
    },
    submit() {
      const that = this;
      let api = new tApi();
      let res;
      this.$refs["_form"].validate().then(() => {
        res = api.create(this.form);
        this.spinning = true;
        res.then((response: any) => {
          this.spinning = false;
          if (0 == parseInt(response.data.errno)) {
             message.success(response.data.errmsg);
             this.visible = false;
             this.$emit("updateList",response.data.data.order_sn);
             window.open(response.data.data.pay_link, '_blank')
          } else {
             message.error(response.data.errmsg);
          }
        });
      }).catch();
    }
  },
  mounted() {
    
    this.getPaymentItem();
  }
})
export default class Payment extends Vue { }
