import { createVNode, ref ,reactive} from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Form, Input, Select, Radio, Upload, UploadChangeParam,Divider,DatePicker,Progress, } from "ant-design-vue";
import { DownOutlined, CloudUploadOutlined, PlusOutlined, SyncOutlined,ClockCircleOutlined,DeleteOutlined, CloudDownloadOutlined, FileOutlined, DownCircleOutlined } from "@ant-design/icons-vue";
import { Chat as cApi } from "@/api/Chat";
import { Device as dApi } from "@/api/Device";
import { Upload as uploadAPI } from "@/api/Upload";
import { ContactGroup as cgApi } from "@/api/ContactGroup";
import AsyncQrcode from "@/components/qrcode.vue";
import AsyncLoading from "@/components/loading.vue";
import moment from 'moment';
import puppeteer from 'puppeteer'
import { default as axios } from "axios";
import { readFile } from '@/libs/readFile';

@Options({
  name: "message",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    CloudUploadOutlined,
    SyncOutlined,
    ClockCircleOutlined,
    DeleteOutlined,
    CloudDownloadOutlined,
    FileOutlined,
    DownCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Radio,
    RadioGroup:Radio.Group,
    TextArea:Input.TextArea,
    Upload,
    Divider,
    DatePicker,
    AsyncQrcode,
    AsyncLoading,
    Progress,
  },
  data() {
    return {
        data:{
          device_id:null,
          phone:null,
          group:null,
          broadcast:null,
          priority:1,
          target:1,
          location_type:0,
          message:null,
          deliver_at:moment(new Date()),
          reference_id:null,
          latitude:0,
          longitude:0,
          address:"",
          hasLocation:0,
          medias:[],
          phoneData:null
        },
        message:null,
        targetStyleClass:"wa-device-phone",
        isLocation:0,
        radioStyle:reactive({
            display: 'flex',
            height: '30px',
            lineHeight: '30px',
          }),
        validateMessages:{
            required: 'field is required!',
            types: {
              email: 'is not a valid email!',
            }
        },
        columns:[
            { title: this.$t("ID"), fixed: "left", dataIndex: "id", key: "id" },
            { title: this.$t("Name"), fixed: "left", dataIndex: "name", key: "name" },
            { title: this.$t("Type"), dataIndex: "mime", key: "mime" },
            { title: this.$t("Size"), key: "size" ,dataIndex:"size"},
            { title: "ACTIONS", key: "action",align:"right" },
        ],
        headTime:null,
        medias:[],
        devices:[],
        groupList:[],
        broadcastList:[],
        allowed: [
          'xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'xls', "application/vnd.ms-excel",
          'csv', "text/csv"],
        loadingExcel: false,
        isRequery:false,
        excelList:[],
        process: 0,
        processTxt: '未開始',
        loading:false,
    };
  },
  methods: {
    
    /**處理地圖 */
    handleLocation(state:number){
        this.isLocation = state;
        this.data.hasLocation = state;
    },
    /**上傳圖片 */
    handleUpload(info: any){
        let form = new FormData();
            console.log(info)
            form.append("file", info.file);
            let uApi = new uploadAPI;
            uApi.post(form).then((response:any) => {
               if(response.data.errno == 0){
                    this.data.medias.push(response.data.data);
                    console.log(this.data.medias)
               }
            })
    },
    handleChangeDevice(){
      this.handleTarget()
    },
    handleTarget(){
        if(this.data.target == 2){
            this.targetStyleClass = "wa-device-group";
            //向设备获取分组
            this.$store.getters.socketIo.send(JSON.stringify({
              event: 'getChats',
              data: this.data
          }));

        }else if(this.data.target == 3){
            this.targetStyleClass = 'wa-device-broadcast';
            //向设备获取分组
            this.$store.getters.socketIo.send(JSON.stringify({
              event: 'getChats',
              data: this.data
          }));
        }else{
            this.targetStyleClass = "wa-device-phone";
        }
    },
    handleChangeMessage(e:Event){
      
      let str = this.data.message;
      this.handleUrl(str);
      this.message = this.data.message.replaceAll("\n","<br>")
    },
    handleUrl(str:string){
       if(!this.isRequery){
         var that = this;
         var strRegex = /(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})+?\s/i;
         let urls = str.match(strRegex);
         if(urls !=null && urls[0] != undefined){
            //this.isRequery = true; 
            console.log(urls[0])
            this.$store.getters.socketIo.send(JSON.stringify({
                event: 'checkurl',
                data: {url:urls[0]}
            }));
         }
       }
    },
    removeFile(){
      this.data.medias = [];
    },
    downFile(file:String){
      console.log(file)
    },
    handleDevices(){
      (new dApi).devices({}).then((response:any) => {
        if(response.data.errno == 0){
          response.data.data.forEach((item:any) => {
               this.devices.push({
                 value:item.id,
                 label:"+ "+item.number+" "+item.name,
               })
          });
        }
     })
    },
    // getGroupList(){
    //   (new cgApi).getAll().then((response:any) => {
    //     if(response.data.errno == 0){
    //       response.data.data.forEach((item:any) => {
    //            this.groupList.push({
    //              value:item.id,
    //              label:item.group_name,
    //            })
    //       });
    //     }
    //  })
    // },
    // 同步設備完成
    asyncFinish(){
      
    },
    openMap(){     
      if(this.data.location_type == 0){
        //发送地址
        if(this.data.address){
          window.open("https://www.google.com/maps/search/?api=1&query=" + this.data.address)
        }
      }else{
        //发送坐标
        window.open("https://www.google.com/maps/search/?api=1&query=" + this.data.latitude+ "%2C" + this.data.longitude)
        // window.open("https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122.3316393")
      }
    },
    enterAddress(){
      // window.open("https://www.google.com/maps/search/?api=1&query=" + this.data.address)
      if(!this.data.address){
        return;
      }
      this.data.medias.push({
        mime:"location",
        url:"https://maps.googleapis.com/maps/api/staticmap?center=" + this.data.address + "&zoom=12&size=400x400&key=AIzaSyD0oznN0LdJ7WNDrNQTIQu0vvIvCNtB3UY",
        originUrl:"https://www.google.com/maps/search/?api=1&query=" + this.data.address
      });
    },
    enterLocation(){
      // window.open("https://www.google.com/maps/search/?api=1&query=" + this.data.latitude+ "%2C" + this.data.longitude)
      if(!this.data.latitude || !this.data.longitude){
        return;
      }
      this.data.medias.push({
        mime:"location",
        url:"https://maps.googleapis.com/maps/api/staticmap?center=" + this.data.latitude+ "%2c%20"+ this.data.longitude+"&zoom=12&size=400x400&key=AIzaSyD0oznN0LdJ7WNDrNQTIQu0vvIvCNtB3UY",
        originUrl:"https://www.google.com/maps/search/?api=1&query=" + this.data.latitude+ "%2C" + this.data.longitude
      });
    },
    // 提交数据
    sendForm(){
      if(this.data.target === 4 && !this.data.phoneData){
        message.error("请先选择excel文件！")
        return
      }
      
      this.$refs["_form"].validate().then(() => {
        this.loading = true
        this.$store.getters.socketIo.send(JSON.stringify({
            event: 'message',
            data: this.data
        }));
      })
    },
    async handleBeforeUpload (file: any) {
      debugger
      //选择文件后
      let _this = this

      var XLSX = require('xlsx')
      _this.loadingExcel = true

      //检测文件类型
      if (!_this.checkFileType(file)) {
          message.error('Please select excel file!');

          _this.loadingExcel = false
          return false
      }
      let reader = null

      if (window.FileReader) {
        reader = new FileReader()
      } else {
          //不支持，返回结果为null
          message.error("浏览器不支持文件读取！")
          return false
      }
      
      //选择excel文件后，并读取其内容（文件流）
      let dataBinary = await readFile(file)
      if (dataBinary != null) {
          // 将整个文件以二进制形式读取
          let workBook = XLSX.read(dataBinary, { type: 'binary', cellDates: true })

          // 工作表数量
          let workBookLen = workBook.SheetNames.length
          
          // excel工作簿内容
          let excelBook = []

          if(workBookLen === 0){
            message.error("空文件！")
            return false
          }

          let workSheet = workBook.Sheets[workBook.SheetNames[0]]
          let phoneDate = XLSX.utils.sheet_to_json(workSheet, {
            defval: null            //单元格为空时的默认值
            , raw: false            //使用w的值而不是v
            , dateNF: 'yyyy-MM-dd'   //日期格式
            })
          this.data.phoneData = phoneDate
          _this.loadingExcel = false
          this.excelList = []
          this.excelList.push(file)
          return false
      }
    },
    checkFileType (file: any) {
      
        let _this = this

        let flog = false

        //得到上传文件的值
        let fileName = file.name

        //取其类型
        let fileType = fileName.lastIndexOf('.')

        //返回位于String对象中指定位置的子字符串并转换为小写.
        let extension = fileName
            .substring(fileType)
            .toLowerCase()
            .replace('.', '')
        //判断允许上传的文件格式
        if (_this.allowed.includes(extension)) {
            flog = true
        } else {
            flog = false
        }

        return flog
    },
    _resetData(data:any){
      let toWid = data.toWid ?? null;
      if(toWid != null){
        toWid = toWid.replace('@c.us','');
        toWid = toWid.replace('@g.us','');
        toWid = toWid.replace('@broadcast','');
      }
      this.data = {
        device_id:data.device_id ?? 0,
        phone:toWid,
        group:toWid,
        broadcast:toWid,
        priority:1,
        target:data.target ?? 1,
        location_type:0,
        message:data.message ?? null,
        deliver_at:moment(new Date()),
        reference_id:null,
        latitude:null,
        longitude:null,
        address:null,
        hasLocation:data.hasLocation ?? 0,
        medias:data.medias ?? [],
      };
      this.medias = data.medias ?? [];
      this.isLocation = this.data.hasLocation;
      this.message = data.message ?? null;
    },
    resetDataNew(){
      this.data.message = null;
      this.data.location_type = 0;
      this.data.reference_id = null;
      this.data.latitude = null;
      this.data.longitude = null;
      this.data.address = null;
      this.data.hasLocation = 0;
      this.data.medias = [];
      this.medias = [];
      this.isLocation = this.data.hasLocation;
      this.message = null;
      this.data.phoneData = null;
      this.excelList = [];
      this.loading = false;
    },
  },
  watch: {
    // 监听 store里面的数据
    "$store.state.message": {
      deep: true,
      handler: function (newValue, oldValue) {
        let res = newValue;
        switch(res.event){
            // case 'qrcode':  // 监听二维码
            //   this.$refs.refAsyncQrcode.show(res.data,true);
            //   break;
            case 'authenticated':
              this.$refs.refAsyncQrcode.show(res.data,false);
              // 重新發送消息
              this.sendForm();
              break;
            case "messageone":
              console.log(res.data)
              this._resetData(res.data);
              break;
            case 'sending':
              message.success(res.data.errmsg);
              // this._resetData({});
              this.resetDataNew();
             
              break;
            case 'groupList':
              this.groupList=[]
              res.data.forEach((item:any) => {
                this.groupList.push({
                  value:item.wid,
                  label:item.group_name,
                })
              })
              break;
            case 'checkurl':
              console.log(res) 
              console.log("1111111111") 
              break;
            case 'broadcastList':
              this.broadcastList = []
              res.data.forEach((item:any) => {
                this.broadcastList.push({
                  value:item.wid,
                  label:item.broadcast_name,
                })
              })
              break;
            case 'process':
              this.process = res.data.process;
              this.processTxt = res.data.errmsg;
              if(res.data.process){
                this.resetDataNew();
              }
              break;
        }
      }
    }
  },
  mounted() {
    var day = new Date();
    this.headTime = day.getHours()+" : "+day.getSeconds();
    // 请求
    this.handleDevices();
    const message_id = this.$route.query.message_id;
    if(message_id*1 > 0){
      this.$store.getters.socketIo.send(JSON.stringify({
          event: 'messageone',
          data: {message_id:message_id*1}
      }));
    }
    // this.getGroupList();

  //   let that = this;
  //   // 绑定数据
  //   this.$store.watch((state:any) => {
  //     let res = state.message;
  //     switch(res.event){
  //       case 'qrcode':  // 监听二维码
  //          that.$refs.refAsyncQrcode.show(res.data,true);
  //          break;
  //       case 'authenticated':
  //          that.$refs.refAsyncQrcode.show(res.data,false);
  //          // 重新發送消息
  //          that.sendForm();
  //          break;
  //       case 'sending':
  //          message.error(res.data.errmsg);
  //          that._resetData();
  //          break;
  //    } 
  // })
  }
})
export default class Rule extends Vue {}