
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message ,Checkbox,Spin,Typography, Tag, Button} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, SmileOutlined, PictureOutlined, CaretUpOutlined,WhatsAppOutlined,FrownOutlined} from "@ant-design/icons-vue";
@Options({
  name: "ConnectWhatsappBtn",
  components: {
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    SmileOutlined,
    PictureOutlined,
    CaretUpOutlined,
    WhatsAppOutlined,
    FrownOutlined,
    Typography,
    TypographyTitle: Typography.Title,
    Button,
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    Checkbox,
    SelectOption: Select.Option,
    TextArea:Input.TextArea,
    Spin
  },
  props:['value'],
  data() {
    return {
      checked: ref(false),
    };
  },
  methods: {
      
  },
  watch:{

  }
})
export default class RuleEdit extends Vue {}
