import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Alert,Switch,Form, Input, Select,Divider,Card,Spin } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, WhatsAppOutlined,LoadingOutlined,MoreOutlined,SettingOutlined} from "@ant-design/icons-vue";
import { Device as Vapi } from "@/api/Device";
import { Meal as mapi } from "@/api/Meal";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import CustomPayment from "@/views/vue/Home/device/payment.vue";
import sessionForm from "@/views/vue/Home/device/session.vue";
import { Trades as tApi } from "@/api/Trades";

@Options({
  name: "Divice",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    WhatsAppOutlined,
    Menu,
    MenuItem: Menu.Item,
    Alert,
    Switch,
    LoadingOutlined,
    MoreOutlined,
    SettingOutlined,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    TextArea:Input.TextArea,
    vueQr,
    Divider,
    Card,
    Spin,
    CustomPayment,
    sessionForm
  },
  data() {
    return {
        validateMessages:{
            required: '${label} is required!',
        },
        syncDeviceImg:require("@/assets/img/sync-device.svg"),
        syncScanqrMp4:require("@/assets/img/scanqr.mp4"),
        data:{
            name:null,
            remaker:null,
            item_id:0,
        },
        trade_sn:'',
        step:1,
        spinning:false,
        items:[],
    };
  },
  mounted() {
    this.getMealPantList();
  },
  methods: {
   // 请求
    getMealPantList(){
        let api = new mapi();
        api.items({}).then((response:any) => {
            if(response.data.errno == 0){
                this.items = response.data.data
            }    
        })
    },
    onSelectPlan(item:any){
        //this.step = 3;
        this.data.item_id = item.id;
        this.data.title = item.title;
        this.data.price = item.price;
        this.$refs.refPayment.paymentForm(this.data);
    },
    onNextStep(trade_sn:any){
        if(this.step == 1){
            this.$refs["_form"].validate().then(() => {
                this.step = 2;
            })
        }else if(this.step == 2){
            this.trade_sn = trade_sn
            this.step = 3;
        }
    },
    onGoBack(){
        if(this.step == 2){
            this.step = 1;
        }else if(this.step == 3){
            this.step = 2;
        }
    },
    checkPayment(){
       
        let api = new tApi();
        this.spinning =true;
        api.check({order_sn:this.trade_sn}).then((response:any) => {
            this.spinning = false;
            if(response.data.errno == 0){
                this.$refs.refSession.addShow(response.data.data);
            }else{
                Modal.warning({
                    content:response.data.errmsg,
                    okText:"OK"
                })
            }
        })
    }
  },
  
})
export default class Rule extends Vue {}