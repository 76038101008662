import {Base, Axios} from "@/api/Base"

export class Device extends Base {
    path = "device"
    qr(params:any): any {
        return Axios.get("device/qr", { params: params })
    }
    connect(params:any): any {
        return Axios.post("device/connect", params)
    }
    put(params:any): any {
        return Axios.post("device/edit", params)
    }
    devices(params:any):any{
        return Axios.get("device/items", { params: params })
    }
    all(params:any):any{
        return Axios.get("device/all", { params: params })
    }
    detail(params:any):any{
        return Axios.get("device/detail", { params: params })
    }
    disable(params:any):any{
        return Axios.get("device/disable", { params: params })
    }
    enable(params:any):any{
        return Axios.get("device/enable", { params: params })
    }
    changestae(params:any):any{
        return Axios.post("device/changestae",params)
    }
    markOffline(params:any):any{
        return Axios.get("device/markOffline", { params: params })
    }
    cusadd(params:any):any{
        return Axios.post("device/cusadd",params)
    }
}