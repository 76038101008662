import { Options, Vue } from "vue-class-component";
import { Row, Col, Empty, Card, Space, message } from "ant-design-vue";
import { MobileOutlined, ClockCircleOutlined, UnorderedListOutlined, ToTopOutlined, MessageOutlined, } from "@ant-design/icons-vue";
import { Dashboard as dashboardApi } from "@/api/Dashboard";
@Options({
    name: "Dashboard",
    components: {
        Row,
        Col,
        Empty,
        Card,
        Space,
        MobileOutlined,
        ClockCircleOutlined,
        UnorderedListOutlined,
        ToTopOutlined,
        MessageOutlined,
        message
    },
    data() {
        return {
            data: {
                messagesInHour: 0,
                messagesInDay: 0,
                messagesInMonth: 0,
                messagesAll: 0,
            }
        }
    },
    methods: {
        getInfo() {
            const api = new dashboardApi();
            api.index().then((response: any) => {
                if (0 === parseInt(response.data.errno)) {
                    this.data = response.data.data
                } else {
                    message.error(response.data.msg);
                }
            });
        }
    },
    mounted() {
        this.getInfo();
    },
})
export default class Dashboard extends Vue { }