
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Typography, Modal, Row, Col, Form, Input, Select, message, Checkbox, Spin, Card, Button } from "ant-design-vue";
import { Product as cApi } from "@/api/Product";
import Payment from "@/views/vue/Home/product/payment.vue";
import { MobileOutlined, ClockCircleOutlined, UnorderedListOutlined, ShoppingCartOutlined, CheckCircleOutlined, } from "@ant-design/icons-vue";

@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    TypographyTitle: Typography.Title,
    Input,
    Select,
    Checkbox,
    CheckboxGroup: Checkbox.Group,
    SelectOption: Select.Option,
    TextArea: Input.TextArea,
    Spin,
    Button,
    Card,
    CardMeta: Card.Meta,
    Payment,
    CheckCircleOutlined,
    ShoppingCartOutlined
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getItems() {
      let api = new cApi;
      api.items({}).then((response: any) => {
        this.items = [];
        if (response.data.errno == 0) {
          this.items = response.data.data
        }
      })
    },
    goSelectPaymentMethod(obj: any) {
      if(obj.price_type == 2){
          message.success("請聯絡平台客服");
      }else{
        this.$refs.refPayment.show(obj);
      }
      
    }
  },
  mounted() {
    this.getItems();
  }
})
export default class RuleEdit extends Vue { }
