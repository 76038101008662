import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Select = _resolveComponent("Select")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Input = _resolveComponent("Input")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Form = _resolveComponent("Form", true)!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "RuleFrom",
    maskClosable: false,
    visible: _ctx.visible,
    onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx._getTitle()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        model: _ctx.data,
        ref: "_form",
        class: "form",
        layout: "vertical",
        "validate-messages": _ctx.validateMessages
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.type'),
                    name: ['type'],
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Select, {
                        value: _ctx.data.type,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.type) = $event)),
                        options: _ctx.groups,
                        placeholder: _ctx.$t('public.placeholder.please_select')
                      }, null, 8, ["value", "options", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.name'),
                    message: "errorMessage",
                    name: ['name'],
                    rules: [{ required: true}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.name'),
                        value: _ctx.data.name,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.name) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.email'),
                    name: "email",
                    rules: [{ type: 'email',required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.email'),
                        value: _ctx.data.email,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.email) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.mobile'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.mobile'),
                        value: _ctx.data.mobile,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.mobile) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.tel'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.tel'),
                        value: _ctx.data.tel,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.tel) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.fax'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.fax'),
                        value: _ctx.data.fax,
                        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.data.fax) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.tag'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.tag'),
                        value: _ctx.data.tag,
                        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.data.tag) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.company'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.company'),
                        value: _ctx.data.companyName,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.data.companyName) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('book.field.address'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('book.field.address'),
                        value: _ctx.data.companyAddress,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.data.companyAddress) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}