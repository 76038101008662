import {Base, Axios} from "@/api/Base"

export class FakeNlpSession extends Base {
    path = "fake_nlp_session"
    index(params:any): any {
        return Axios.get("fake_nlp_session/index", { params: params })
    }
    
    get(params:any): any {
        return Axios.get("fake_nlp_session/get", { params: params })
    }

    getAll(): any {
        return Axios.get("fake_nlp_session/getAll")
    }
    
}