import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message,Input } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
import { FastAnswer as aApi } from "@/api/FastAnswer";
import CustomFrom from "@/views/vue/Home/fastAnswer/form.vue";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    CustomFrom,
    Input,
  },
  data() {
    return {
      search: "",
      searchForm: {
        search: ""
      },
      order: "",
      pagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("book.table.id"), fixed: "left", dataIndex: "id", key: "id" },
        { title: this.$t("fastAnswer.table.response"), fixed: "left", dataIndex: "fast_answer", key: "fast_answer" },
        { title: this.$t("public.name.action"), key: "action",align:"right" },
      ],
      rowSelection : ref({
        checkStrictly: false,
          onChange: (selectedRowKeys: (string | number)[]) => {
        },
        
      })
    };
  },
  methods: {
    chang_page(pagination: any) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new aApi();
      api
        .get({
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          search: this.searchForm.search,
          order: this.order,
        })
        .then((response:any) => {
          this.loading = false;
          if (0 === parseInt(response.data.errno)) {
            this.list = response.data.data.data;
            this.pagination.total = response.data.data.count
            this.pagination.current = response.data.data.currentPage
          } else {
            this.list = [];
          }
        });
    },
    
    add(pid: number) {
      this.$refs.refRuleEdit.addShow(pid);
    },
    edit(id: number,data:any) {
      this.$refs.refRuleEdit.editShow(id,data);
    },
    del(id: number) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new aApi();
          api.delete({id:id}).then((response:any) => {
            if (0 === parseInt(response.data.errno)) {
              this.get_list();
              message.success(response.data.errmsg);
            } else {
              message.error(response.data.errmsg);
            }
          });
        },
      });
    },
    handleTableChange(pagination: any){  // 分页
      this.pagination = pagination;
      this.get_list();
    }
  },
  mounted() {
    this.get_list();
  },
})
export default class Rule extends Vue {}