import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "GroupEdit",
    visible: _ctx.visible,
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx._getTitle(),
    zIndex: 9999999900
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        model: _ctx.data,
        layout: "vertical",
        rules: _ctx.validateRules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                xs: 24,
                sm: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('public.field.name')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('public.field.name'),
                        value: _ctx.data.name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.name) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 12
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('public.field.status')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Select, {
                        value: _ctx.data.status,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.status) = $event)),
                        placeholder: _ctx.$t('public.placeholder.please_select'),
                        dropdownStyle: {zIndex: 9999999950}
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectOption, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('public.status.1')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_SelectOption, { value: 2 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('public.status.2')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}