import {Base, Axios} from "@/api/Base"

export class ContactGroup extends Base {
    path = "contact_group"
    index(params:any): any {
        return Axios.get("contact_group/index", { params: params })
    }
    post(param: any): any {
        return Axios.post("contact_group/add", param)
    }
    put(param: any): any {
        return Axios.put("contact_group/edit", param)
    }
    delete(param: any): any {
        return Axios.delete("contact_group/delete", { params: param })
    }
    getGroupBook(params:any): any {
        return Axios.get("contact_group/getGroupBook", { params: params })
    }

    getAll(): any {
        return Axios.get("contact_group/getAll")
    }
    
}