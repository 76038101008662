
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert,Table } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { UserAccount as cApi } from "@/api/UserAccount";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    Table,
    TextArea:Input.TextArea,
    InputSearch:Input.Search
  },
  data() {
    return {
      visible: false,
      actionType: "",
      search:'',
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      columns: [
        { title: this.$t("accountList.table.referenceNumber"), fixed: "left", dataIndex: "trace_sn", key: "trace_sn" },
        { title: this.$t("accountList.table.account"), dataIndex: "nickname", key: "nickname" },
        { title: this.$t("交易金額"), dataIndex: "total", key: "total" },
        { title: this.$t("手續費"), dataIndex: "fee", key: "fee" },
        { title: this.$t("accountList.table.amount"), dataIndex: "amount", key: "amount" },
        { title: this.$t("accountList.table.preAmount"), dataIndex: "before", key: "amount" },
        { title: this.$t("accountList.table.newAmount"), dataIndex: "after",  key: "after" },
        
        { title:this.$t("accountList.table.remark"),key: "remaker",align:"right"  },
        { title: this.$t("accountList.table.createAt"), dataIndex: "created_at", key: "created_at" },
      ],
      agent_id:0,
      list:[],
      data:{},
      loading: false,
    };
  },
  methods: {
    async listsShow(data:any) {
      this.actionType = "edit";
      this.visible = true;
      this.data = data;
      this.get_list();
    },
    //点击关闭按钮
    _close(){
        this.visible = !this.visible
      },
      get_list() {
        this.loading = true;
        const api = new cApi(); 
        api
          .logs({
            get_type: "list",
            page: this.tablePagination.current,
            limit: this.tablePagination.pageSize,
            search: this.search,
            agent_id : this.data.user_id            
          })
          .then((response:any) => {
            this.loading = false;
            if (0 === parseInt(response.data.errno)) {
              const dataTmp = response.data.data;
              this.tablePagination.current = parseInt(dataTmp.page);
              this.tablePagination.pageSize = parseInt(dataTmp.limit);
              this.tablePagination.total = parseInt(dataTmp.total);
              this.list = dataTmp.data;
            } else {
              this.list = [];
            }
          });
      },
  },
  watch: {
    
  },
  mounted() {
    
  }
})
export default class RuleEdit extends Vue {}