import {Base, Axios} from "@/api/Base"

export class Meal extends Base {
    path = "meal"
    setSort(params:any): any {
        return Axios.post("meal/setSort", params)
    }
    items(params:any): any {
        return Axios.post("meal/items", params)
    }
} 