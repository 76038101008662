
import { Options, Vue } from "vue-class-component";
import { createVNode, defineComponent } from 'vue';
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Select, Form, Card, } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons-vue";
import { Payment as cApi } from "@/api/Payment";
import { Order } from "@/api/Order";


@Options({
  name: "Payment",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    Modal,
    Select,
    SelectOption: Select.Option,
    Form,
    FormItem: Form.Item,
    Card,
    CardMeta: Card.Meta,
  },
  data() {
    return {
      data: null,
      visible: false,
      form: {
        payment: null,
        product_id: null,
        user_id: null,
        amount: null,
      },
      selectList: {
        paymentMethodList: []
      },
      validateMessages: {
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
    };
  },
  methods: {
    getItems() {
      let api = new cApi;
      api.items().then((response: any) => {
        this.selectList.paymentMethodList = [];
        if (response.data.errno == 0) {
          this.selectList.paymentMethodList = response.data.data
        }
      })
    },
    show(obj: any) {
      this.data = obj
      this.form.product_id = obj.id
      this.form.amount = obj.price
      this.visible = true
    },
    submit() {
      const that = this;
      let api = new Order();
      let res;
      this.$refs["_form"].validate().then(() => {

        res = api.create(this.form);
        this.$store.commit("setLoading", true);
        res.then((response: any) => {
          this.$store.commit("setLoading", false);
          if (0 == parseInt(response.data.errno)) {
            message.success(response.data.errmsg);
            window.open(response.data.data, '_blank')
            Modal.confirm({
              title: () => '已完成支付？',
              icon: () => createVNode(ExclamationCircleOutlined),
              content: () => createVNode('div', { style: 'color:red;' }, '完成支付請按確認並自動刷新頁面！'),
              onOk() {
                that.$emit("updateList");
              },
              onCancel() {
                that.$emit("updateList");
              },
              class: 'test',
            });
            this.visible = false;
          } else {
            message.error(response.data.errmsg);
          }
        });
      }).catch();

    }
  },
  mounted() {
    this.getItems();
    this.form.user_id = this.$store.getters.getUser.id;
  }
})
export default class Payment extends Vue { }
