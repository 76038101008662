import { Base, Axios } from "@/api/Base"

export class Rule extends Base {
    path = "rule"
    getList(params:any): any {
        return Axios.get("rule/list", { params: params })
    }
    getMenus(params:any): any {
        return Axios.get("rule/menus", { params: params })
    }
    getAll(): any {
        return Axios.get("rule/all")
    }
}