
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Chat as cApi } from "@/api/Chat";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    TextArea:Input.TextArea
  },
  data() {
    return {
      visible: false,
      actionType: "",
      data: {
        name: "",
        address:'',
        company_name:"",
        remark:"",
      },
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
    };
  },
  methods: {
    async editShow(data:any) {
      this.actionType = "edit";
      this.data = data
      this.visible = true;
    },
   
    // reset data
    _resetData() {
      this.data = {
        name: "",
        remaker:'',
        number:null,
        device_id:0
      };
    },
    send(){
        let api = new cApi();
        let res;
        let _this = this;
        this.$refs["_form"].validate().then(() => {
        api.editClient(this.data).then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              this.visible = false;
              message.success(response.data.errmsg);
              this.$emit("updateList");
            } else {
              message.error(response.data.errmsg);
            }
          });
          
      }).catch();
    }
  },
  mounted() {
    
    
  }
})
export default class RuleEdit extends Vue {}
