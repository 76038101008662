
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message ,Checkbox,Spin,InputNumber} from "ant-design-vue";
import { Meal as Vapi } from "@/api/Meal";

@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    Checkbox,
    CheckboxGroup:Checkbox.Group,
    SelectOption: Select.Option,
    TextArea:Input.TextArea,
    Spin,
    InputNumber
  },
  data() {
    return {
      visible: false,
      actionType: "",
      groups:[
          {
            value: 0,
            label: "免费",
        },
        {
            value: 1,
            label: "月租",
        },
        {
            value: 2,
            label: "定制",
        }
      ],
      data: {
        price_type: null,
        name: "",
        email: "",
        password: '',
        status: 1,
        nickname: "",
      },
      
      requiredPassword:true,
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
 
    };
  },
  methods: {
    async addShow(pid: number) {
      this.actionType = "add";
      this._resetData();
      this.data.pid = pid;
      this.visible = true;
    },
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      this.data = data
      this.visible = true;
      this.requiredPassword = false;
    },
    // send put/post
    send() {
      
      let api = new Vapi();
      let res;
      this.$refs["_form"].validate().then(() => {
        switch (this.actionType) {
            case "add":
              res = api.post(this.data);
              break;
            case "edit":
              if (!this.data.id && this.data.id <= 0) {
                message.warning(this.$t("rule.validate.required.id"));
                return false;
              }
              res = api.put(this.data);
              break;
            default:
              return false;
          }
          this.$store.commit("setLoading",true);
          res.then((response:any) => {
            this.$store.commit("setLoading",false);
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
      
    },
   
    // reset data
    _resetData() {
      this.data = {
        title:null,
        description:null,
        price:0,
        whats_app_number:1,
        is_text_message:0,
        is_media_message:0,
        is_queued_message:0,
        sort:0,
        status:1,
      
      };
      this.$store.commit("setLoading",false);
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
    
  },
})
export default class RuleEdit extends Vue {}
