import { createVNode } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message ,Switch,Input} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined ,SyncOutlined,ContainerOutlined} from "@ant-design/icons-vue";
import { UserAccount as cApi } from "@/api/UserAccount";
import GroupEdit from "@/components/Home/GroupEdit.vue";
import accountList from "@/views/vue/Home/user_account/accountList.vue";
@Options({
  name: "Group",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    Menu,
    MenuItem: Menu.Item,
    GroupEdit,
    Switch,
    SyncOutlined,
    ContainerOutlined,
    accountList,
    Input,
    InputSearch:Input.Search
  },
  data() {
    return {
      searchForm:{
        search:""
      },
      search: "",
      order: "",
      tablePagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      isMerchant:false,
      columns: [
        { title: this.$t("userAccount.table.id"), fixed: "left", dataIndex: "user_id", key: "user_id" },
        { title: this.$t("userAccount.table.agentName"), fixed: "left", dataIndex: "nickname", key: "nickname" },
        { title: this.$t("userAccount.table.agentEmail"), fixed: "left", dataIndex: "email", key: "email" },
        { title: this.$t("userAccount.table.balance"), fixed: "left", dataIndex: "amount", key: "amount" },
        { title: this.$t("userAccount.table.commissionRate"), fixed: "left",  key: "rate" },
        { title: this.$t("入賬手續費"), fixed: "left",  key: "fee" },
        { title: this.$t("userAccount.table.createAt"), fixed: "left", dataIndex: "created_at", key: "created_at" },
        { title: this.$t("public.table.status"), key: "state"},
        { key: "action",align:"right"  },
      ],
    };
  },
  methods: {
    chang_page(pagination: any) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new cApi(); 
      api
        .get({
          get_type: "list",
          page: this.tablePagination.current,
          limit: this.tablePagination.pageSize,
          search: this.search,
        })
        .then((response:any) => {
          this.loading = false;
          if (0 === parseInt(response.data.errno)) {
            const dataTmp = response.data.data;
            this.tablePagination.current = parseInt(dataTmp.page);
            this.tablePagination.pageSize = parseInt(dataTmp.limit);
            this.tablePagination.total = parseInt(dataTmp.total);
            this.isMerchant = dataTmp.isMerchant;
            this.list = dataTmp.data;
          } else {
            this.list = [];
          }
        });
    },
    add() {
      this.$refs.refGroupEdit.addShow();
    },
    edit(id: number) {
      this.$refs.refGroupEdit.editShow(id);
    },
    lists(obj: any){
      this.$refs.accountList.listsShow(obj);
    },
    edit_rule(obj: any) {
      // console.log(obj)
      this.$refs.refGroupRule.editShow(obj);
    },
    // 改变状态
    onChangeState(e:any,record:any){
      var oldState = record.state;
      record.state = record.state == 1 ? 0 : 1;
      //打開開關前，先檢測是否已經設定了nlp_client_email和nlp_private_key

      const api = new cApi();
      api.changestae({
        state: record.state,
        id:record.id
      })
      .then((response:any) => {
        this.loading = false;
        if (0 === parseInt(response.data.errno)) {
          
        } else {
          message.success(response.data.errmsg);
          record.record = oldState;
        }
      });
    },
  },
  mounted() {
    this.get_list();
  },
})
export default class Group extends Vue {}