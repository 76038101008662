import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_SelectOption = _resolveComponent("SelectOption")!
  const _component_Select = _resolveComponent("Select")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_TextArea = _resolveComponent("TextArea")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "RuleFrom",
    maskClosable: false,
    visible: _ctx.visible,
    onCancel: _cache[5] || (_cache[5] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx._getTitle()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        model: _ctx.data,
        ref: "_form",
        class: "form",
        layout: "vertical",
        "validate-messages": _ctx.validateMessages
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('payment.table.name'),
                    name: ['name'],
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('payment.table.name'),
                        value: _ctx.data.name,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.name) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('payment.table.code'),
                    message: "errorMessage",
                    name: ['code'],
                    rules: [{ required: true}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('payment.table.code'),
                        value: _ctx.data.code,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.code) = $event)),
                        disabled: true
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fields, (field, k) => {
                return (_openBlock(), _createBlock(_component_Col, {
                  xs: 24,
                  sm: 24
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_FormItem, {
                      label: field.label,
                      message: "errorMessage",
                      name: field.field,
                      rules: [{ required: field.required}]
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Input, {
                          placeholder: _ctx.$t('book.field.name'),
                          value: _ctx.data.option[field.field],
                          "onUpdate:value": ($event: any) => ((_ctx.data.option[field.field]) = $event)
                        }, null, 8, ["placeholder", "value", "onUpdate:value"])
                      ]),
                      _: 2
                    }, 1032, ["label", "name", "rules"])
                  ]),
                  _: 2
                }, 1024))
              }), 256)),
              _createVNode(_component_Col, {
                xs: 8,
                sm: 8
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('public.name.status')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Select, {
                        value: _ctx.data.status,
                        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.status) = $event)),
                        placeholder: _ctx.$t('public.placeholder.please_select')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_SelectOption, { value: 0 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('public.status.2')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_SelectOption, { value: 1 }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('public.status.1')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "placeholder"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 8,
                sm: 8
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('payment.table.sort'),
                    rules: [{ required: false}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputNumber, {
                        placeholder: _ctx.$t('payment.table.sort'),
                        value: _ctx.data.sort,
                        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.sort) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('payment.table.description') 
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TextArea, {
                        value: _ctx.data.remaker,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.remaker) = $event)),
                        class: "message-textarea"
                      }, null, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}