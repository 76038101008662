import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "cs-device-number" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = ["textContent"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatsAppOutlined = _resolveComponent("WhatsAppOutlined")!
  const _component_CheckOutlined = _resolveComponent("CheckOutlined")!
  const _component_CloseOutlined = _resolveComponent("CloseOutlined")!
  const _component_ChatBox = _resolveComponent("ChatBox")!
  const _component_TabPane = _resolveComponent("TabPane")!
  const _component_Tabs = _resolveComponent("Tabs")!
  const _component_ConnectWhatsappBtn = _resolveComponent("ConnectWhatsappBtn")!
  const _component_AsyncQrcode = _resolveComponent("AsyncQrcode")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.devices.length > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_Tabs, {
            style: {"background-color":"white"},
            class: "cs-devices",
            activeKey: _ctx.activeKey,
            "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.activeKey) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.devices, (device, index) => {
                return (_openBlock(), _createBlock(_component_TabPane, {
                  key: device.value
                }, {
                  tab: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode(_component_WhatsAppOutlined),
                      _createElementVNode("b", {
                        innerHTML: device.name
                      }, null, 8, _hoisted_3)
                    ]),
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        textContent: _toDisplayString(device.number)
                      }, null, 8, _hoisted_4)
                    ]),
                    (device.state === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_CheckOutlined, { style: {fontSize: '16px', color: '#00ff00'} }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('chatBox.name.online')), 1)
                        ]))
                      : _createCommentVNode("", true),
                    (device.state === 0)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          onClick: ($event: any) => (_ctx.goViewDevice(device.value))
                        }, [
                          _createVNode(_component_CloseOutlined, { style: {fontSize: '16px', color: '#ff0000'} }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t('chatBox.name.offline')), 1)
                        ], 8, _hoisted_6))
                      : _createCommentVNode("", true)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_ChatBox, {
                      device_id: device.value
                    }, null, 8, ["device_id"])
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey"])
        ]))
      : (_openBlock(), _createBlock(_component_ConnectWhatsappBtn, {
          key: 1,
          ref: "ConnectWhatsappBtn"
        }, null, 512)),
    _createVNode(_component_AsyncQrcode, {
      ref: "refAsyncQrcode",
      onUpdateList: _ctx.asyncFinish
    }, null, 8, ["onUpdateList"])
  ], 64))
}