import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Alert } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, WhatsAppOutlined} from "@ant-design/icons-vue";
import { Chat as cApi } from "@/api/Chat";
import CustomFrom from "@/views/vue/Home/device/form.vue";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    WhatsAppOutlined,
    Menu,
    MenuItem: Menu.Item,
    CustomFrom,
    Alert,
  },
  data() {
    return {
      search: "",
      order: "",
      pagination: {
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
      list: [],
      columns: [
        { title: this.$t("history.table.id"), fixed: "left", dataIndex: "id", key: "id" },
        { title: this.$t("history.table.user"), key: "nickname",dataIndex:"nickname" },
        { title: this.$t("history.table.device"), key: "name",dataIndex:'name' },
        { title: this.$t("history.table.target"), key: "target" },
        { title: this.$t("history.table.target_wid"),  key: "toWid" ,dataIndex:'toWid'},
        { title: this.$t("history.table.reference_id"),  key: "reference_id" ,dataIndex:'reference_id'},
        { title: this.$t("history.table.priority"), key: "priority" },
        { title: this.$t("history.table.message"), key: "message" ,dataIndex:'message'},
        { title: this.$t("history.table.create_at"), dataIndex: "created_at", key: "created_at" },
        { title: this.$t("public.name.action"), key: "action", align:"right" },
      ],
      rowSelection : ref({
        checkStrictly: false,
          onChange: (selectedRowKeys: (string | number)[]) => {
        },
      }),
    };
  },
  methods: {
    chang_page(pagination: any) {
      this.tablePagination = pagination;
      this.get_list();
    },
    get_list() {
      this.loading = true;
      const api = new cApi();
      api
        .get({
          page: this.pagination.current,
          limit: this.pagination.pageSize,
          search: this.search,
          order: this.order,
        })
        .then((response:any) => {
          this.loading = false;
          
          if (0 === parseInt(response.data.errno)) {
            this.list = response.data.data.data;
            this.pagination.total = response.data.data.count
            this.pagination.current = response.data.data.currentPage
          } else {
            this.list = [];
          }
        });
    },
    del(id: number) {
      Modal.confirm({
        title: this.$t("public.name.are_you_sure_delete") + "?",
        content: this.$t("public.name.action_cannot_resumed"),
        icon: createVNode(ExclamationCircleOutlined),
        okText: this.$t("public.name.yes"),
        okType: "danger",
        cancelText: this.$t("public.name.no"),
        onOk: () => {
          const api = new cApi();
          api.delete({id:id}).then((response:any) => {
            if (0 === parseInt(response.data.errno)) {
              this.get_list();
              message.success(response.data.errmsg);
            } else {
              message.error(response.data.errmsg);
            }
          });
        },
      });
    },
    handleTableChange(pagination: any){  // 分页
      this.pagination = pagination;
      this.get_list();
    }
  },
  mounted() {
    this.get_list();
    
  },
})
export default class Rule extends Vue {}