
import { ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Divider,Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Upload, message, Image, Card, List, ListItemMeta, Descriptions, Input, Space, Popover, Select, Avatar, Badge } from "ant-design-vue";
import { DownOutlined,TransactionOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, SmileOutlined, PictureOutlined, CaretUpOutlined ,SearchOutlined,FormOutlined, AudioOutlined,MessageOutlined} from "@ant-design/icons-vue";
import { Chat as cApi } from "@/api/Chat";
import { ClientRemark as crApi } from "@/api/ClientRemark";
import { Device as dApi } from "@/api/Device";
import { FastAnswer as fApi } from "@/api/FastAnswer";
import { Upload as uploadAPI } from "@/api/Upload";
import EditFrom from "@/views/vue/Home/customerService/editClient.vue";
import AddRemark from "@/views/vue/Home/customerService/addRemark.vue";
import EasyLinkForm from "@/components/Home/EasyLinkForm.vue";
import { any } from "vue-types";
import { AnyCnameRecord } from "dns";
@Options({
  name: "ChatBox",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    SmileOutlined,
    PictureOutlined,
    CaretUpOutlined,
    Menu,
    MenuItem: Menu.Item,
    Card,
    List,
    ListItem: List.Item,
    ListItemMeta: List.Item.Meta,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    Input,
    Space,
    Popover,
    TextArea: Input.TextArea,
    Select,
    Upload,
    Image,
    Avatar,
    UploadDragger: Upload.Dragger,
    Badge,
    EditFrom,
    AddRemark,
    FormOutlined,
    InputSearch:Input.Search,
    Divider,
    SearchOutlined,
    AudioOutlined,
    MessageOutlined,
    TransactionOutlined,
    EasyLinkForm
  },
  props:['device_id'],
  data() {
    return {
      emojis: require('emojis-list'),
      emojisVisible: false,
      fastAnswerVisible: false,
      currentClientId: null,
      devices: [],
      clientRemarkList:[],
      data: {
        device_id: null,
        client_id: null,
        to: null,
        message: "",
        created_at: null,
        medias: []
      },
      split: false,
      showDropdown:false,
      sessionListData: [
      ],
      sessionData: [
      ],
      answerData: [
      ],
      clientInfo: null,
      showUploadDragger: false,
      sessionValue:null,
      sessionList:[],
      quillIndex:0,
    };
  },
  mounted(){
    this.data.device_id = this.device_id
    this.getFastAnswer();
    this.getSessionList(this.device_id);
  },
  methods: {
      getSessionList(device_id : number) {
        const that = this;
        // this.$store.getters.socketIo.send(JSON.stringify({
        //   event: 'memberlist',
        //   data: { device_id: device_id }
        // }));
         this.$store.commit("socketSend",JSON.stringify({
          event: 'memberlist',
          data: { device_id: device_id }
        }));
        return true;
        const api = new cApi();
        api.sessionList({ device_id: device_id }).then((response: any) => {
          if (0 === parseInt(response.data.errno)) {
            this.sessionListData = response.data.data
            //message.success(response.data.errmsg);
            var sessionList:any = [];
            this.sessionListData.forEach(function(item:any){
              sessionList.push({value:item.id,label:item.phone,name:item.name,search:item.name+item.phone})
            })
            this.sessionList = sessionList;
          } else {
            message.error(response.data.errmsg);
          }
        });
    },
    handleSearch(val: string) {
      console.log(val)
      if(val == null || val == ''){
        this.showDropdown = false;
      }else{
        this.showDropdown = true;
      }
    },
    handleSelect(val:any, option:any){
      console.log(val);
      this.sessionValue = null;
      this.showDropdown = false;
      this.selectSession({id:option.value,phone:option.label});
    },
    filterSessionOption(input: string, option: any){
       let result = option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0;
       
       return result;
    },
    startPayment(){
      let quill = this.$refs.refMmessageTxt;
      
      // this.quillIndex = quill.selection.savedRange.index;
      // quill.insertText(this.quillIndex, "reererererer");
      this.$refs.refEasyLinkForm.addShow();
    },
    updateInputContent(parse:any){
      console.log(parse)
      this.data.message = this.data.message+" "+parse.url
    },
    getChatList() {
      var _that = this
      const api = new cApi();
      api.chatList({ 
        device_id: this.device_id,
        client_id: this.currentClientId }).then((response: any) => {
        if (0 === parseInt(response.data.errno)) {
          this.sessionData = response.data.data
          this.sessionData.forEach((item: any) => {
            if (item.media) {
              item.media = JSON.parse(item.media)
            }
          })
          this.$nextTick(() => {
            document.getElementById('gundong')!.scrollTop = document.getElementById('gundong')!.scrollHeight

          })
          // message.success(response.data.errmsg);
        } else {
          message.error(response.data.errmsg);
        }
      });
    },
    getClient(id: number) {
      const api = new cApi();
      api.getClient({ id: id }).then((response: any) => {
        if (0 === parseInt(response.data.errno)) {
          this.clientInfo = response.data.data
          // message.success(response.data.errmsg);
        } else {
          message.error(response.data.errmsg);
        }
      });
    },
    getClientRemarkList(id: number) {
      const api = new crApi();
      api.getAll({ client_id: id }).then((response: any) => {
        if (0 === parseInt(response.data.errno)) {
          this.clientRemarkList = response.data.data
          // message.success(response.data.errmsg);
        } else {
          message.error(response.data.errmsg);
        }
      });
    },

    selectSession(session: any) {
      this.data.to = session.phone
      // this.data.device_id = session.device_id
      this.data.client_id = session.id
      
      this.currentClientId = session.id
      this.getChatList()
      this.getClient(session.id)
      this.read(session.id)
      this.getClientRemarkList(session.id)
    },
    pcikeEmoji() {

    },
    dragEnterEvent(e: any) {
      if (this.currentClientId) {
        this.showUploadDragger = true;
      }

    },
    dragEnterdragend(e:any){
      console.log(e)
      if(this.showUploadDragger){
        this.showUploadDragger = false;
      }
    },
    pasteFile(e:any){
      let txt = e.clipboardData.getData('Text')
      //检测到是文字，过滤
      if(typeof(txt) == 'string' && txt !== '') {
	      return ;
	    }
      let file = null
      const items = (e.clipboardData).items;
      console.log(items.length)
      for (let i = 0; i < items.length; i++) {
        console.log(items[i])
        if (items[i].type != '') {
          file = items[i].getAsFile()
          this.handleUpload({file:file});
          break
        }
      }
    },
    send(){
      if(!this.currentClientId){
        message.error('請選擇會話')
        return
      }
      if (!this.data.device_id) {
        message.error('請選擇設備')
        return
      }
      if (!this.data.message && !this.data.medias.length) {
        message.error('請輸入要發送的信息')
        return
      }

      this.$store.getters.socketIo.send(JSON.stringify({
        event: 'chatMessage',
        data: this.data
      }));
    },
    addEmojis(emojis: string) {
      this.data.message += (emojis)
      this.emojisVisible = false
    },
    edit() {
      if (this.clientInfo) {
        this.$refs.refEditFrom.editShow(this.clientInfo);
      }

    },
    clickAnswer(answer: string) {
      if (!this.currentClientId) {
        message.error('請選擇會話')
        return
      }
      if (!this.data.device_id) {
        message.error('請選擇設備')
        return
      }

      this.data.message = answer
      this.$store.getters.socketIo.send(JSON.stringify({
        event: 'chatMessage',
        data: this.data
      }));
      this.fastAnswerVisible = false;
    },
    read(client_id : number){
      const api = new cApi();
      api.read({ client_id: client_id }).then((response: any) => {
        if (0 === parseInt(response.data.errno)) {
          let i = this.sessionListData.findIndex( (item:any) => {
            return item.id == client_id
          })
          this.sessionListData[i].unread = 0

        } else {
          // message.error(response.data.errmsg);
        }
      });
    },
    handleDevices() {
      (new dApi).all({}).then((response: any) => {
        if (response.data.errno == 0) {
          response.data.data.forEach((item: any) => {
            this.devices.push({
              value: item.id,
              label: "+ " + item.number + " " + item.name,
            })
          });
          if(response.data.data.length > 0){
            this.changeDevice(response.data.data[0].id)
          }

        }
      })
      
    },
    getFastAnswer() {
      (new fApi).all({}).then((response: any) => {
        if (response.data.errno == 0) {

          this.answerData = response.data.data
        }
      })
    },
    /**上傳圖片 */
    handleUpload(info: any){
      this.showUploadDragger = false;
      if(!this.currentClientId){
        message.error('請選擇會話')
        return
      }
      if (!this.data.device_id) {
        message.error('請選擇設備')
        return
      }
      let form = new FormData();
          form.append("file", info.file);
          let uApi = new uploadAPI;
          uApi.post(form).then((response:any) => {
             if(response.data.errno == 0){
                  this.data.medias.push(response.data.data);
                  this.send();
             }
          })
    },
    addRemark(){
      this.$refs.refAddRemark.addShow(this.currentClientId);
    },
    updateRemark(){
      this.getClientRemarkList(this.currentClientId)
    },
    startRecord(){
      // initialisation of voicereco
      console.log(window)
      var recognition = new (window as any).webkitSpeechRecognition()
      recognition.lang = this.lang_;
      recognition.interimResults = true;

      // event current voice reco word
      recognition.onresult = (event : any) => {
        console.log('You said: ', event.results[0][0].transcript);
        this.data.message = event.results[0][0].transcript
      };
      // end of transcription
      recognition.onend = (() => {
        // this.transcription_.push(this.runtimeTranscription_);
        // this.runtimeTranscription_ = "";
        recognition.stop();
      });
      recognition.start();
    },
    stopRecord(){

    }
  },
  watch:{
    // 监听 store里面的数据
    "$store.state.message": {
      deep: true,
      handler: function (newValue:any, oldValue:any) {
        let e = newValue;
        switch (e.event) {
          case 'sending':
            this.data.message = "";
            this.data.medias = [];
            this.getChatList(this.device_id);
            break;
          case 'memberlist_'+this.device_id:
            let members = e.data;
            if(members.length > 0){
              console.log(members)
              this.sessionListData = members
              var sessionList:any = [];
              this.sessionListData.forEach(function(item:any){
                 sessionList.push({value:item.id,label:item.phone,name:item.name,search:item.name+item.phone})
              })
              this.sessionList = sessionList;
            }else{
                // this.sessionListData = []
               // this.sessionList = [];
            }
            
            break;
          case 'reply':
            this.getSessionList(this.device_id);
            if (this.currentClientId) {
              this.getChatList(this.device_id);
            }

            break;
          // case 'disconnected':

          //   message.error(e.data.errmsg);

          //   break;
        }
      }
    }
  }
})
export default class RuleEdit extends Vue {}
