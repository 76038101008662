
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Device as Vapi } from "@/api/Device";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import store from '@/store'
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    vueQr,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    TextArea:Input.TextArea
  },
  data() {
    return {
      visible: false,
      actionType: "",
      groups:[
          {
            value: "whatsapp",
            label: "WhatsApp",
        },
        {
            value: "facebook",
            label: "Facebook",
        },
        {
            value: "twitter",
            label: "Twitter",
        }
      ],
      data: {
        name: "",
        remaker:'',
        device_id:0
      },
      requiredPassword:true,
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
      clientId:null,
      qr:"loading qrcode",
      isLoadingQr:false,
      modalFormQr:null,
      hasLoadQr:false,
      ws:store.getters.socketIo,
    };
  },
  methods: {
    async addShow(pid: number) {
      this.actionType = "add";
      this._resetData();
      this.data.pid = pid;
      this.visible = true;
      this.clientId=null;
      this.qr="loading qrcode";
      this.isLoadingQr=false;
      this.modalFormQr=null;
      this.hasLoadQr=false;
    },
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      data.password = '';
      this.data = data
      this.visible = true;
      this.requiredPassword = false;
    },
    // send put/post
    connect() {
      let api = new Vapi();
      let res;
      let _this = this;
      this.$refs["_form"].validate().then(() => {
         this.ws.send(JSON.stringify({
              event: 'adddevice',
              data: this.data
          }));
        return ;
        api.connect(this.data).then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              // message.success(response.data.errmsg);
              // this.$emit("updateList");
              this.clientId = response.data.data.clientId;
              _this.getQr(_this);
              this.modalFormQr = "hidden-footer"
              this.isLoadingQr = true;
              
              //this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
          
      }).catch();
    },
    //点击关闭按钮
    connectClose(){
      console.log('connectClose');
      this.visible = !this.visible
      this.ws.send(JSON.stringify({
              event: 'adddeviceclose',
              data: this.data
          }));
    },
    // 请求
    getQr(_this:any){
      let api = new Vapi();
      api.qr({clientId:this.clientId}).then((response:any) => {
          if(response.data.errno == 0){
              var qr = response.data.data.qr;
              var state = response.data.data.state
              if((qr == null || qr == '') && state != 1){
                  setTimeout(function(){
                    _this.getQr(_this);
                  },3000)
              }else{
                this.hasLoadQr = true;
                if(state != 1){
                   this.qr = qr;
                   setTimeout(function(){
                    _this.getQr(_this);
                  },5000)
                }else{
                  this.visible = false;
                  message.success(response.data.errmsg);
                  this.$emit("updateList");
                }
                
              }
          }
      })
    },
    // reset data
    _resetData() {
      this.data = {
        name: "",
        remaker:'',
        device_id:0
      };
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
  watch: {
    // 监听 store里面的数据
    "$store.state.message": {
      deep: true,
      handler: function (newValue, oldValue) {
        let e = newValue;
        switch(e.event){
            case 'qrcode':  // 监听二维码
            //   if(e.data != null){
            //       this.qr = e.data;
            //       this.modalFormQr = "hidden-footer"
            //       this.isLoadingQr = true;
            //       this.hasLoadQr = true;
            //   }
            //   console.log(e.data,'qrcode...');
              break;
            case 'create': // 监听创建
              if(0 == parseInt(e.data.errno)){
                this.data.device_id=e.data.data
                this.modalFormQr = "hidden-footer"
                this.isLoadingQr = true;
              }else{
                 message.error(e.data.errmsg);
              }
              break;
            case 'asyncdevice':
              if(0 == parseInt(e.data.errno)){
                this.visible = false;
                message.success(e.data.errmsg);
                this.$emit("updateList");
              }else{
                message.error(e.data.errmsg);
              }
              break;
        }
      }
    }
  },
  mounted() {
    // // 监听后端发送来的消息
    // var that = this;
    // // 监听socket消息吧变化
    // this.$store.watch((state:any) => {
    //     let e = state.message;
    //     switch(e.event){
    //         case 'qrcode':  // 监听二维码
    //           if(e.data != null){
    //               that.qr = e.data;
    //               that.modalFormQr = "hidden-footer"
    //               that.isLoadingQr = true;
    //               that.hasLoadQr = true;
    //           }
    //           console.log(e.data,'qrcode...');
    //           break;
    //         case 'create': // 监听创建
    //           if(0 == parseInt(e.data.errno)){
    //             that.data.device_id=e.data.data
    //             that.modalFormQr = "hidden-footer"
    //             that.isLoadingQr = true;
    //           }else{
    //              message.error(e.data.errmsg);
    //           }
    //           break;
    //         case 'asyncdevice':
    //           if(0 == parseInt(e.data.errno)){
    //             that.visible = false;
    //             message.success(e.data.errmsg);
    //             that.$emit("updateList");
    //           }else{
    //             message.error(e.data.errmsg);
    //           }
    //           break;
    //     }
    // })
  }
})
export default class RuleEdit extends Vue {}
