
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message } from "ant-design-vue";
import { ApiKey as Vapi } from "@/api/ApiKey";
import { Device as Aapi } from "@/api/Device";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      
      data: {
        name:"",
        device_id:null,
        webhook:'',
      },
      devices:[],
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
 
    };
  },
  methods: {
    async addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
      this.handleDevices();
    },
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      this.data = data
      this.visible = true;
      this.handleDevices();
    },
    // send put/post
    send() {
      let api = new Vapi();
      let res;
      this.$refs["_form"].validate().then(() => {
        switch (this.actionType) {
            case "add":
              res = api.post(this.data);
              break;
            case "edit":
              if (!this.data.id && this.data.id <= 0) {
                message.warning(this.$t("rule.validate.required.id"));
                return false;
              }
              res = api.put(this.data);
              break;
            default:
              return false;
          }
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
      
    },
    handleDevices(){
      this.devices = [];
      (new Aapi).devices({}).then((response:any) => {
        if(response.data.errno == 0){
          response.data.data.forEach((item:any) => {
               this.devices.push({
                 value:item.id,
                 label:"+ "+item.number+" "+item.name,
               })
          });
        }
     })
    },
  
    // reset data
    _resetData() {
      this.data = {
        name: "",
        device_id:null,
        webhook:'',
      };
      
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
})
export default class RuleEdit extends Vue {}
