
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message } from "ant-design-vue";
import { Group as groupApi } from "@/api/Group";

@Options({
  name: "GroupEdit",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      data: {
        pid: 0,
        name: "",
        status: 1,
        condition: "",
      },
      validateRules: {
        name: [
          {
            required: true,
            message: this.$t("public.validate.required.name"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    addShow() {
      this.actionType = "add";
      this._resetData();
      this.visible = true;
    },
    async editShow(id: number) {
      this.actionType = "edit";
      await this._getMsg(id);
      this.visible = true;
    },
    // send put/post
    send() {
      let api = new groupApi();
      let res;
      switch (this.actionType) {
        case "add":
          res = api.post(this.data);
          break;
        case "edit":
          if (!this.data.id && this.data.id <= 0) {
            message.warning(this.$t("public.validate.required.id"));
            return false;
          }
          res = api.put(this.data);
          break;
        default:
          return false;
      }
      res.then((response:any) => {
        if (1 == parseInt(response.data.code)) {
          message.success(response.data.msg);
          this.$emit("updateList");
          this.visible = false;
        } else {
          message.error(response.data.msg);
        }
      });
    },
    async _getMsg(id: any) {
      let api = new groupApi();
      await api
        .get({
          get_type: "only",
          search: id,
        })
        .then((response:any) => {
          if (1 === parseInt(response.data.code)) {
            this.data = response.data.data;
          }
        });
    },
    // reset data
    _resetData() {
      this.data = {
        name: "",
        status: 1,
      };
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
  },
})
export default class GroupEdit extends Vue {}
