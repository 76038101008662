import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "Dashboard",
  class: "gutter-example"
}
const _hoisted_2 = { class: "number" }
const _hoisted_3 = { class: "number" }
const _hoisted_4 = { class: "number" }
const _hoisted_5 = { class: "number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageOutlined = _resolveComponent("MessageOutlined")!
  const _component_Space = _resolveComponent("Space")!
  const _component_Card = _resolveComponent("Card")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Row, {
      gutter: 16,
      class: "card-row"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Col, {
          class: "gutter-row",
          span: 6
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, {
              title: _ctx.$t('dashboard.name.sent_messages_last_hour'),
              bordered: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Space, { size: 150 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.data.messagesInHour), 1),
                    _createVNode(_component_MessageOutlined, { class: "icon" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_Col, {
          class: "gutter-row",
          span: 6
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, {
              title: _ctx.$t('dashboard.name.sent_messages_last_day'),
              bordered: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Space, { size: 150 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.data.messagesInDay), 1),
                    _createVNode(_component_MessageOutlined, { class: "icon" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_Col, {
          class: "gutter-row",
          span: 6
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, {
              title: _ctx.$t('dashboard.name.sent_messages_last_month'),
              bordered: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Space, { size: 150 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.data.messagesInMonth), 1),
                    _createVNode(_component_MessageOutlined, { class: "icon" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        }),
        _createVNode(_component_Col, {
          class: "gutter-row",
          span: 6
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Card, {
              title: _ctx.$t('dashboard.name.total_sent_messages'),
              bordered: false
            }, {
              default: _withCtx(() => [
                _createVNode(_component_Space, { size: 150 }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.data.messagesAll), 1),
                    _createVNode(_component_MessageOutlined, { class: "icon" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}