import {Base, Axios} from "@/api/Base"
export class Chat extends Base {
    path = "chat"
    message(params:any): any {
        return Axios.post("chat/message", params)
    }
    send(params:any): any {
        return Axios.post("chat/send", params)
    }
    sessionList(params:any): any {
        return Axios.get("chat/sessionList", { params: params })
    }
    chatList(params:any): any {
        
        return Axios.get("chat/chatList", { params: params })
    }
    getClient(params:any): any {
        
        return Axios.get("chat/getClient", { params: params })
    }
    editClient(params:any): any {
        return Axios.post("chat/editClient", params)
    }

    read(params:any): any {
        return Axios.post("chat/read", params)
    }

    find(params:any): any {
        
        return Axios.get("chat/find", { params: params })
    }
    
}