import {Base, Axios} from "@/api/Base"

export class FakeNlpMessage extends Base {
    path = "fake_nlp_message";
    index(params:any): any {
        return Axios.get("fake_nlp_message/index", { params: params })
    }
    
    get(params:any): any {
        return Axios.get("fake_nlp_message/get", { params: params })
    }

    getAll(): any {
        return Axios.get("fake_nlp_message/getAll")
    }
    
}