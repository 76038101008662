
import { createVNode, ref, reactive } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, message, Form, Input, Select, Radio, Upload, UploadChangeParam, Divider, DatePicker, Image } from "ant-design-vue";
import { DownOutlined, CloudUploadOutlined, PlusOutlined, SyncOutlined, ClockCircleOutlined, DeleteOutlined, CloudDownloadOutlined, FileOutlined, DownCircleOutlined, ReloadOutlined } from "@ant-design/icons-vue";
import { Device as dApi } from "@/api/Device";
import AsyncQrcode from "@/components/qrcode.vue";
import Async from "@/views/vue/Home/device/async.vue";
import store from '@/store'
// store.dispatch("socket");
@Options({
    name: "message",
    components: {
        Row,
        Col,
        Table,
        TypographyTitle: Typography.Title,
        Tag,
        Button,
        Image,
        Dropdown,
        DownOutlined,
        PlusOutlined,
        CloudUploadOutlined,
        SyncOutlined,
        ClockCircleOutlined,
        DeleteOutlined,
        CloudDownloadOutlined,
        FileOutlined,
        DownCircleOutlined,
        ReloadOutlined,
        Menu,
        MenuItem: Menu.Item,
        Form,
        FormItem: Form.Item,
        Input,
        Select,
        SelectOption: Select.Option,
        Radio,
        RadioGroup: Radio.Group,
        TextArea: Input.TextArea,
        Upload,
        Divider,
        DatePicker,
        AsyncQrcode,
        Async,
    },
    data() {
        return {
            data: {
                device: null,
                phone: null,
                group: null,
                broadcast: null,
                priority: 1,
                target: 1,
                location_type: 0,
                message: null,
                deliver_at: '',
                reference_id: null,
                latitude: null,
                longitude: null,
                address: null,
                medias: []
            },
            deviceId: 0,
            headTime: "00:00",
            device: {},
            asynced: false,
            ws: store.getters.socketIo,//new WebSocket('ws://127.0.0.1:8360/ws?transports=websocket'),
        };
    },
    methods: {
        getDeviceInfo(id: number) {
            (new dApi).detail({ deviceId: id }).then((response: any) => {
                if (response.data.errno == 0) {
                    this.device = response.data.data;
                }
            })
        },
        enableAction(id: number) {

            (new dApi).enable({ deviceId: id }).then((response: any) => {
                if (response.data.errno == 0) {
                    this.device = response.data.data;
                }
            })
        },
        disableAction(id: number) {
            (new dApi).disable({ deviceId: id }).then((response: any) => {
                if (response.data.errno == 0) {
                    this.device = response.data.data;
                }
            })
        },
        markOfflineAction(id: number) {
            (new dApi).markOffline({ deviceId: id }).then((response: any) => {
                if (response.data.errno == 0) {
                    this.device = response.data.data;
                }
            })
        },
        updateStatus() {
            if (this.device.status == 0) {
                this.enableAction(this.deviceId);
            } else {
                this.disableAction(this.deviceId);
            }
        },
        asyncDevice() {  // 同步设备
            this.asynced = true;
            this.ws.send(JSON.stringify({
                event: 'deviceasync',
                data: {
                    deviceId: this.deviceId,
                }
            }));
            this.$refs.refAsync.show(true);
        },
        traAgain(){
            let that = this
            this.$nextTick(function(){
                 that.asyncDevice();
            });
        },
        timeout(){
            this.asynced = false;
            this.markOfflineAction(this.deviceId);
        }
    },
    watch: {
        // 监听 store里面的数据
        "$store.state.message": {
            deep: true,
            handler: function (newValue, oldValue) {
                let e = newValue;

                if (e.event == 'qrcode') { // 监听二维码
                    if (e.data != null && e.data.id === this.deviceId) {
                        this.$refs.refAsyncQrcode.show(e.data.qr, true);
                    }
                } else if (e.event == 'asyncdevice') {
                    this.getDeviceInfo(this.deviceId);
                }
                else {
                    this.asynced = false;
                    this.$refs.refAsyncQrcode.show(e.data, false);
                    this.$refs.refAsync.show(false);
                    message.success(e.data.errmsg)
                }
            }
        }
    },
    mounted() {
        var day = new Date();
        this.headTime = day.getHours() + " : " + day.getSeconds();
        this.deviceId = this.$route.query.deviceId
        this.getDeviceInfo(this.deviceId);

        // 监听socket后端发送来的消息
        // let that = this;
        // this.$store.watch((state: any) => {
        //     let e = state.message;
        //     console.log(e)
        //     if (e.event == 'qrcode') { // 监听二维码
        //         if (e.data != null) {
        //             that.$refs.refAsyncQrcode.show(e.data, true);
        //         }
        //     } else if (e.event == 'asyncdevice') {
        //         this.getDeviceInfo(this.deviceId);
        //     }
        //     else {
        //         that.asynced = false;
        //         that.$refs.refAsyncQrcode.show(e.data, false);
        //     }
        // })
        // var ios = io('http://127.0.0.1:8360',{transports:['websocket','xhr-polling','jsonp-polling']})
        // var that = this;
    }
})
export default class Rule extends Vue { }
