import {Base, Axios} from "@/api/Base"

export class ClientRemark extends Base {
    path = "client_remark"

    add(params:any): any {
        return Axios.post("client_remark/add", params)
    }

    edit(params:any): any {
        return Axios.post("client_remark/edit", params)
    }

    delete(params:any): any {
        return Axios.post("client_remark/delete", params)
    }

    getAll(params:any): any {
        return Axios.get("client_remark/getAll",{ params: params })
    }
    
}