import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tag, Button, Dropdown, Menu, Modal, Upload, message, Image, Card, List, ListItemMeta, Descriptions, Input, Space, Popover, Select, Avatar, Badge,Tabs } from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined, SmileOutlined, PictureOutlined, CaretUpOutlined,WhatsAppOutlined,FrownOutlined,CheckOutlined,CloseOutlined} from "@ant-design/icons-vue";
import { Chat as cApi } from "@/api/Chat";
import { Device as dApi } from "@/api/Device";
import { FastAnswer as fApi } from "@/api/FastAnswer";
import { Upload as uploadAPI } from "@/api/Upload";
import EditFrom from "@/views/vue/Home/customerService/editClient.vue";
import AsyncQrcode from "@/components/qrcode.vue";
import ChatBox from "@/components/ChatBox.vue";
import ConnectWhatsappBtn from "@/components/ConnectWhatsappBtn.vue";
import moment from "moment";

@Options({
  name: "Cs",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tag,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    SmileOutlined,
    PictureOutlined,
    CaretUpOutlined,
    WhatsAppOutlined,
    FrownOutlined,
    CheckOutlined,
    CloseOutlined,
    Menu,
    MenuItem: Menu.Item,
    Card,
    List,
    ListItem: List.Item,
    ListItemMeta: List.Item.Meta,
    Descriptions,
    DescriptionsItem: Descriptions.Item,
    Input,
    Space,
    Popover,
    TextArea: Input.TextArea,
    Select,
    Upload,
    Image,
    EditFrom,
    Avatar,
    UploadDragger: Upload.Dragger,
    AsyncQrcode,
    Badge,
    Tabs,
    TabPane:Tabs.TabPane,
    ChatBox,
    ConnectWhatsappBtn
  },
  data() {
    return {
      activeKey: null,
      devices: [],
    };
  },
  methods: {
    
    handleDevices() {
      (new dApi).all({}).then((response: any) => {
        if (response.data.errno == 0) {
          response.data.data.forEach((item: any) => {
            this.devices.push({
              value: item.id,
              label: "+ " + item.number + " " + item.name,
              number:"+" + item.number,
              name:item.name,
              state:item.state
            })
          });
          if(response.data.data.length > 0){
            this.activeKey = response.data.data[0].id
          }

        }
      })
      
    },
    markOfflineAction(id: number) {
      (new dApi).markOffline({ deviceId: id }).then((response: any) => {
          if (response.data.errno == 0) {
              this.device = response.data.data;
          }
      })
    },
    goViewDevice(id:number){
      this.$router.push('/device/view?deviceId='+id)
    }
  },
  watch: {
    // 监听 store里面的数据
    "$store.state.message": {
      deep: true,
      handler: function (newValue:any, oldValue:any) {
        let e = newValue;
        switch (e.event) {
          // case 'sending':
          //   this.data.message = "";
          //   this.data.medias = [];
          //   this.getChatList();
          //   break;

          // case 'reply':
          //   this.getSessionList();
          //   if (this.currentClientId) {
          //     this.getChatList();
          //   }

          //   break;
          case 'asyncdevice':
            message.error(e.data.errmsg);
            
            this.devices.forEach((device:any) => {

              if(device.value === e.data.device_id){
                device.state = 1
              }
            })
            break;
          case 'disconnected':
            message.error(e.data.errmsg);
            let deviceId = e.data.id;
            this.devices.forEach((device:any) => {

              if(device.value === deviceId){
                device.state = 0
                this.markOfflineAction(deviceId)
              }
            })
            break;
         
        }
      }
    }
  },
  mounted() {
    this.handleDevices();
  }
})
export default class Cs extends Vue { }