import {Base, Axios} from "@/api/Base"

export class EasyOrder extends Base {
    path = "EasyOrder"
    create(params:any): any {
        return Axios.post("easy_order/index", params)
    }
    get(params:any): any {
        return Axios.get("easy_order/index", { params: params })
    }
}