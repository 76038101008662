
import { Row, Col, Empty, Card,Space,Form, Input, Select, message, Alert,Divider,Button,Radio,InputNumber,Table } from "ant-design-vue";
import { SettingOutlined, EditOutlined, EllipsisOutlined,MoneyCollectOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref,reactive,onMounted } from 'vue';
import { Country as Vapi } from "@/api/Country";
import { Auth as capi } from "@/api/Auth";
import type { FormInstance } from 'ant-design-vue';
import { useI18n } from "vue-i18n";




export default defineComponent({
  components: {
    SettingOutlined,
    EditOutlined,
    EllipsisOutlined,
    MoneyCollectOutlined,
    Row,
    Table,
    Col,
    Empty,
    Card,
    Space,
    CardMeta: Card.Meta,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    Divider,
    Button,
    InputPassword:Input.Password,
    RadioGroup: Radio.Group,
    RadioButton: Radio.Button,
    InputNumber
  },
   setup() {
    const form = ref({
      accountForm: {
        nickname: "",
        email: "",
        language: "",
        timeZone: "",
        type: 0,
        companyAddress:"",
        city: "",
        postalCode: "",
        country_id: "",
        companyName:'',
        is_agent:0,
        url:'',
        rate:0,
        webhook: "",
      },
      passwordForm:{
        currentPassword:"",
        newPassword:"",
        confirmPassword:"",
      },
    });
    const t = useI18n().t;
    let pagination = ref({
      pageSizeOptions: ["10", "20", "50", "100"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 0,
    });
    const columns = ref(
      [
        { title: "ID", fixed: "left", dataIndex: "id", key: "id" },
        { title: t('userInfo.table.reference'), fixed: "left", dataIndex: "trace_sn", key: "trace_sn" },
        { title: t('userInfo.table.payer'), dataIndex: "nickname", key: "nickname" },
        { title: t('交易金額'), key: "total" ,dataIndex:"total"},
        { title: t('手續費'), key: "fee" ,dataIndex:"fee"},
        { title: t('userInfo.table.amount'), key: "amount" ,dataIndex:"amount"},
        { title: t('userInfo.table.pre_amount'), dataIndex: "before", key: "before" },
        { title: t('userInfo.table.new_amount'), dataIndex: "after", key: "after" },
        { title: t('userInfo.table.remark'), dataIndex: "remarks", key: "remarks" },
        { title: t('userInfo.table.create_at'), dataIndex: "created_at", key: "created_at" }
      ]
    );
    let search = ref({
      page:1,
      limit:10,
      keywords:null
    })
    let dataSource = ref<any>(null);
    const accountFormDom = ref<any>([]);
    const options = ref();
    let loading = ref();
    const submit = () => {
      
    }
    const selectList = {
      paymentMethodList: []
    }
    const countrys = () => {
      let api = new Vapi();
      api.items().then((response:any) => {
          var items:any= [];
          response.data.data.forEach(function(item:any,index:number){
             items.push({value:item.id,label:item.name})
          })
          options.value = items;
      });
    }
    // 點擊分頁
    const handleTableChange = (pagination: any) => {
      search.value.page = pagination.current;
      search.value.limit = pagination.pageSize;
      console.log(pagination)
      proceeds();
    }
    // 收益數據列表
    const proceeds = () => {
      let _api = new capi();
      loading.value = true;
      _api.proceeds(search.value).then((response:any) => {
         var items:any= [];
         loading.value = false;
          dataSource.value = response.data.data.data;
          pagination.value.total = response.data.data.count;
          pagination.value.current = response.data.data.currentPage
          pagination.value.pageSize = response.data.data.pageSize
      });
    }
    

    onMounted(()=>{
      // 获取国家信息
      countrys();
      // 获取用户信息
      (new capi()).account({}).then((response:any) => {
          form.value.accountForm = response.data.data
      })
      proceeds();
    })
    
    const onFinish = (values: any) => {
      accountFormDom.value.validate().then(() => {
        (new capi()).update(form.value.accountForm).then((response:any) => {
          if(response.data.errno == 0){
            message.success(response.data.errmsg);
          }else{
            message.warning(response.data.errmsg);
          }
            
        })
      });
    }
    const validateMessages = {
      required: '${label} is required!',
      types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
      },
      number: {
        range: '${label} must be between ${min} and ${max}',
      },
    }
    const copyAgentUrl = function(e:any){
      console.log(e)
      var docu = document.createElement("input");//document.getElementById('agent-url')
          docu.value = form.value.accountForm.url
          document.body.appendChild(docu);
          docu.select();
          document.execCommand('Copy');
          document.body.removeChild(docu)
    }
    return {
      form,
      submit,
      validateMessages,
      onFinish,
      selectList,
      countrys,
      accountFormDom,
      options,
      copyAgentUrl,
      columns,
      dataSource,
      loading,
      handleTableChange,
      pagination
    };
  }
});
