import {Base, Axios} from "@/api/Base"

export class Nlp extends Base {
    path = "nlp"
    index(params:any): any {
        return Axios.get("nlp/index", { params: params })
    }

    post(param: any): any {
        return Axios.post("nlp/add", param)
    }
    
    delete(param: any): any {
        return Axios.delete("nlp/delete", { params: param })
    }
    
}