
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { Device as Vapi } from "@/api/Device";
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import store from '@/store'
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    vueQr,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined
  },
  data() {
    return {
      visible: false,
      qr:null,
      modalFormQr:"hidden-footer",
    };
  },
  methods: {
    async show(qr:string,visible:boolean) {
      this.visible = visible;
      this.qr = qr;
    },
  },
  mounted() {
    
  }
})
export default class RuleEdit extends Vue {}
