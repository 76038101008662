
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message } from "ant-design-vue";
import { Rule as ruleApi } from "@/api/Rule";

@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
  },
  data() {
    return {
      visible: false,
      actionType: "",
      menus:[],
      data: {
        pid: 0,
        name: "",
        content: "",
        type: 1,
        status: 1,
        condition: "",
      },
      validateRules: {
        title: [
          {
            required: true,
            message: this.$t("public.validate.required.name"),
            trigger: "blur",
          },
        ],
        path:[
          {
            required: true,
            message: this.$t("請輸入目錄"),
            trigger: "blur",
          }
        ]
      },
    };
  },
  methods: {
    addShow(pid: number) {
      this.actionType = "add";
      this._resetData();
      this.data.pid = pid;
      this.visible = true;
      this._getMenus()
    },
    async editShow(id: number,data:any) {
      this.actionType = "edit";
      await this._getMsg(id,data);
      this.visible = true;
    },
    // send put/post
    send() {
      let api = new ruleApi();
      let res;
      this.$refs["_form"].validate().then(() => {
        switch (this.actionType) {
            case "add":
              res = api.post(this.data);
              break;
            case "edit":
              if (!this.data.id && this.data.id <= 0) {
                message.warning(this.$t("rule.validate.required.id"));
                return false;
              }
              res = api.put(this.data);
              break;
            default:
              return false;
          }
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.msg);
            }
          });
      }).catch();
      
    },
    async _getMsg(id: any, data:any) {
      this.data = data
      
    },
    // reset data
    _resetData() {
      this.data = {
        pid: 0,
        name: "",
        path: "",
        type: 'url',
        status: 1,
        condition: "",
      };
    },
    _getTitle() {
      let name = "";
      switch (this.actionType) {
        case "add":
          name = this.$t("public.name.add");
          break;
        case "edit":
          name = this.$t("public.name.edit");
          break;
      }
      return name;
    },
     /**获取分组 */
    _getMenus(){
      let api = new ruleApi();
      const res = api.getMenus({}).then((response:any) => {
        this.menus = [];
        if(response.data.errno == 0){
          this.menus = response.data.data
        }
      });
    },
  },
})
export default class RuleEdit extends Vue {}
