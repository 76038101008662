import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "desc" }
const _hoisted_2 = { style: {"font-size":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Progress = _resolveComponent("Progress")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CloseCircleOutlined = _resolveComponent("CloseCircleOutlined")!
  const _component_Result = _resolveComponent("Result")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "RuleFrom",
    maskClosable: false,
    footer: null,
    visible: _ctx.visible,
    title: _ctx.$t("async.name.title"),
    closable: false
  }, {
    default: _withCtx(() => [
      (!_ctx.showTimeOut)
        ? (_openBlock(), _createBlock(_component_Row, { key: 0 }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                span: 24,
                style: {"text-align":"center"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Progress, {
                    type: "circle",
                    percent: _ctx.percent
                  }, null, 8, ["percent"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_ctx.showTimeOut)
        ? (_openBlock(), _createBlock(_component_Row, { key: 1 }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, { span: 24 }, {
                default: _withCtx(() => [
                  _createVNode(_component_Result, {
                    status: "error",
                    title: _ctx.$t('async.name.asyncFailed'),
                    "sub-title": _ctx.$t('async.name.asyncFailedMessage')
                  }, {
                    extra: _withCtx(() => [
                      _createVNode(_component_Button, {
                        onClick: _ctx.close,
                        type: "primary"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("async.name.close")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"]),
                      _createVNode(_component_Button, { onClick: _ctx.tryAgain }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t("async.name.again")), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, [
                        _createElementVNode("p", _hoisted_2, [
                          _createElementVNode("strong", null, _toDisplayString(_ctx.$t("async.name.asyncFailedTipsTitle")), 1)
                        ]),
                        _createElementVNode("p", null, [
                          _createVNode(_component_CloseCircleOutlined, { style: { color: 'red' } }),
                          _createTextVNode(" " + _toDisplayString(_ctx.$t("async.name.asyncFailedTips")), 1)
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["title", "sub-title"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}