import {Base, Axios} from "@/api/Base"

export class Product extends Base {
    path = "product"
    setSort(params:any): any {
        return Axios.post("product/setSort", params)
    }
    items(params:any): any {
        return Axios.post("item/index", params)
    }
} 