
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined } from "@ant-design/icons-vue";
import { ClientRemark as cApi } from "@/api/ClientRemark";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    TextArea:Input.TextArea
  },
  data() {
    return {
      visible: false,
      actionType: "",
      data: {
        title: "",
        remark:'',
      },
      validateMessages:{
        required: '${label} is required!',
        types: {
          email: 'is not a valid email!',
        }
      },
    };
  },
  methods: {
    async addShow(client_id:number) {
      this.actionType = "add";
      this.data = {
        client_id: client_id,
        title: "",
        remark:'',
      },
      this.visible = true;
    },
   
    // reset data
    _resetData() {
      this.data = {
        title: "",
        remark:'',
      }
    },
    send(){
        let api = new cApi();
        this.$refs["_form"].validate().then(() => {
        api.add(this.data).then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              this.visible = false;
              message.success(response.data.errmsg);
              this.$emit("updateRemark");
            } else {
              message.error(response.data.errmsg);
            }
          });
          
      }).catch();
    }
  },
  mounted() {
    
    
  }
})
export default class RuleEdit extends Vue {}
