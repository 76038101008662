
import { Options, Vue } from "vue-class-component";
import { Modal, Row, Col, Form, Input, Select, message, Alert,Progress,Result,Button } from "ant-design-vue";
import { MoreOutlined, SettingOutlined, LoadingOutlined,CloseCircleOutlined } from "@ant-design/icons-vue";
@Options({
  name: "RuleFrom",
  components: {
    Modal,
    Row,
    Col,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
    Alert,
    MoreOutlined,
    SettingOutlined,
    LoadingOutlined,
    CloseCircleOutlined,
    TextArea:Input.TextArea,
    Progress,
    Result,
    Button
  },
  data() {
    return {
      visible: false,
      percent: 0,
      showTimeOut: false,
      timer:null,
    };
  },
  methods: {
    async show(visible: boolean) {
      this.percent = 0;
      this.showTimeOut = false;
      this.visible = visible;
      if(visible){
        this.timer = setInterval(() => {
              this.percent += 5
              console.log(this.percent);
              if(this.percent >= 100){
                this.showTimeOut = true
                this.$emit("timeout");
                clearInterval(this.timer)
                this.timer = null
              }
            }, 1000);
      }else{
        clearInterval(this.timer)
                this.timer = null
      }
    },
   
    // reset data
    _resetData() {
    },
    close(){
      this.visible = false;
    },
    tryAgain(){
      this.$emit("tryAgain");
      this.show(false)
    },
    send(){
        
    }
  },
  mounted() {
    
    
    
  }
})
export default class RuleEdit extends Vue {}
