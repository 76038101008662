import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Input = _resolveComponent("Input")!
  const _component_FormItem = _resolveComponent("FormItem")!
  const _component_Col = _resolveComponent("Col")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Row = _resolveComponent("Row")!
  const _component_Form = _resolveComponent("Form")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createBlock(_component_Modal, {
    id: "RuleFrom",
    maskClosable: false,
    visible: _ctx.visible,
    onCancel: _cache[2] || (_cache[2] = ($event: any) => (_ctx.visible = !_ctx.visible)),
    onOk: _ctx.send,
    title: _ctx._getTitle()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_Form, {
        model: _ctx.data,
        ref: "_form",
        class: "form",
        layout: "vertical",
        "validate-messages": _ctx.validateMessages
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Row, { gutter: [16,16] }, {
            default: _withCtx(() => [
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('問題'),
                    message: "errorMessage",
                    name: ['question'],
                    rules: [{ required: true}]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Input, {
                        placeholder: _ctx.$t('問題'),
                        value: _ctx.data.question,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.question) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }),
              _createVNode(_component_Col, {
                xs: 24,
                sm: 24
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_FormItem, {
                    label: _ctx.$t('答案'),
                    name: "answer",
                    rules: [{ required: true }]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Textarea, {
                        placeholder: _ctx.$t('答案'),
                        value: _ctx.data.answer,
                        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.answer) = $event))
                      }, null, 8, ["placeholder", "value"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "validate-messages"])
    ]),
    _: 1
  }, 8, ["visible", "onOk", "title"]))
}