
import { Options, Vue } from "vue-class-component";
import { Modal, Descriptions } from "ant-design-vue";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons-vue";


@Options({
  name: "Payment",
  components: {
    Modal,
    LoadingOutlined,
    PlusOutlined,
    Descriptions,
    DescriptionsItem:Descriptions.Item,
  },
  data() {
    return {
      selectList:{
      },
      data: {
      },
      visible: false,
    };
  },
  methods: {
    async detailShow(obj:any) {
      this.data = JSON.parse(JSON.stringify(obj));
      this.visible = true;
    },
  },
  mounted() {
    
  }
})
export default class Detail extends Vue { }
