import {Base, Axios} from "@/api/Base"

export class Payment extends Base {
    path = "addon/payment"
    items(): any {
        return Axios.get("payment/items")
    }
    changestae(params:any):any{
        return Axios.post("addon/payment/changestae",params)
    }
    find(params:any):any{
        return Axios.get("addon/payment/find",{params:params})
    }
    setupel(params:any):any{
        return Axios.post("addon/payment/setupel",params)
    }
    paylike(params:any):any{
        return Axios.post("addon/payment/paylike",params)
    }
    questInfo(params:any):any{
        return Axios.get("addon/payment/questInfo",{params:params})
    }
} 