
import { createVNode, ref } from "vue";
import { Options, Vue } from "vue-class-component";
import { Row, Col, Table, Typography, Tabs , Button, Dropdown, Menu, Modal, message ,Form,Input, Select} from "ant-design-vue";
import { DownOutlined, ExclamationCircleOutlined, PlusOutlined, SyncOutlined} from "@ant-design/icons-vue";
import { General as cApi } from "@/api/General.ts";
@Options({
  name: "Rule",
  components: {
    Row,
    Col,
    Table,
    TypographyTitle: Typography.Title,
    Tabs,
    TabPane:Tabs.TabPane,
    Button,
    Dropdown,
    DownOutlined,
    PlusOutlined,
    ExclamationCircleOutlined,
    SyncOutlined,
    Menu,
    MenuItem: Menu.Item,
    Form,
    FormItem: Form.Item,
    Input,
    Select,
    SelectOption: Select.Option,
  },
  data() {
    return {
      activeKey: ref('1'),
      data:{
        name:null,
        zone:null,
        logo:null,
        copyright:null,
      },
      email:{
        sender:'',
        service:'',
        port:22,
        username:'',
        password:'',
        mail_verify:''
      },
      validateMessages:{
        required: 'field is required!',
            types: {
                email: 'is not a valid email!',
            }
        },
    };
  },
  methods: {
    send() {
      let api = new cApi();
      let res;
      this.$refs["_form"].validate().then(() => {
          res = api.config(this.data);
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
    },
    sendEmail() {
     let api = new cApi();
      let res;
      this.$refs["_sendEmail"].validate().then(() => {
          res = api.email(this.email);
          res.then((response:any) => {
            if (0 == parseInt(response.data.errno)) {
              message.success(response.data.errmsg);
              this.$emit("updateList");
              this.visible = false;
            } else {
              message.error(response.data.errmsg);
            }
          });
      }).catch();
    },
    /**获取分组 */
    _getInfo(key:String){
      let api = new cApi();
      const res = api.info({key:key}).then((response:any) => {
        this.groups = [];
        if(response.data.errno == 0){
           let data = response.data.data
           this.data = Object.assign(this.data,data.general || {});
           this.email =  Object.assign(this.email,data.email || {});
           console.log(this.data)
        }
      });
    },
  },
  mounted() {
      this._getInfo('general');
  },
})
export default class Rule extends Vue {}
