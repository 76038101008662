import {Base, Axios} from "@/api/Base"

export class Trades extends Base {
    path = "trade"
    create(params:any): any {
        return Axios.post("trade/create", params)
    }
    check(params:any): any {
        return Axios.get("trade/check", { params: params })
    }
    
}